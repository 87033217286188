import { Inject, Injectable } from '@angular/core';
import { API451_CLIENT, ApiClient } from '@element451-libs/api451';
import { pusherApiKey, pusherCluster } from '@element451-libs/config451';
import Pusher, { Channel } from 'pusher-js';

const PUSHER_SUBSCRIPTION_SUCCEEDED = 'pusher:subscription_succeeded';
const PUSHER_SUBSCRIPTION_ERROR = 'pusher:subscription_error';

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  constructor(@Inject(API451_CLIENT) public api451Client: ApiClient) {}

  listenChannel(userId: string): PusherChannel {
    const socket = new Pusher(pusherApiKey, {
      authEndpoint: getPusherAuthUrl(this.api451Client),
      cluster: pusherCluster,
      auth: {
        params: {},
        headers: {
          feature: this.api451Client.featureToken,
          Authorization: this.api451Client.authorization
        }
      }
    });

    const channelName = getPusherChannelName(userId);
    return new PusherChannel(socket, channelName);
  }
}

export class PusherChannel {
  private _channel: Channel;

  constructor(
    public socket: Pusher,
    public channelName: string
  ) {
    this._channel = socket.subscribe(channelName);
  }

  listenSubscription(callback: (data: boolean) => void) {
    if (this._channel) {
      this._channel.bind(PUSHER_SUBSCRIPTION_SUCCEEDED, () => callback(true));
      this._channel.bind(PUSHER_SUBSCRIPTION_ERROR, () => callback(false));
    }
  }

  listenEvent(event: string, callback: (data: any) => void) {
    if (this._channel) {
      this._channel.bind(event, callback);
    }
  }

  disconnectSubscription() {
    this._channel.unbind(PUSHER_SUBSCRIPTION_SUCCEEDED);
    this._channel.unbind(PUSHER_SUBSCRIPTION_ERROR);
  }

  disconnectEvent(event: string) {
    this._channel.unbind(event);
  }

  disconnect() {
    this._channel.unbind_all();
    this.socket.unsubscribe(this.channelName);
  }
}

function getPusherChannelName(userId: string) {
  return `private-${userId}`;
}

function getPusherAuthUrl(client: ApiClient) {
  return `${client.apiUrl}notifications/alerts/auth`;
}
