import { CountUsersByTerritoriesQuery } from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { CountUsersByTerritoriesQuery };

export const countUsersByTerritoriesQuery = gql<
  CountUsersByTerritoriesQuery,
  void
>`
  query CountUsersByTerritories {
    countUsersByTerritories {
      territories
    }
  }
`;
