import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormsApiService, responseData } from '@element451-libs/api451';
import { FormsApi } from '@element451-libs/models451';
import { truthy } from '@element451-libs/utils451/rxjs';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { exhaustMap, map, tap, withLatestFrom } from 'rxjs';

interface State {
  verificationResponse: FormsApi.FormVerificationResponse;
  token: string;
  loadingVerification: boolean;
  loadingConfirmation: boolean;
  verificationError: string | null;
  confirmationError: string | null;
}

const initialState: State = {
  verificationResponse: { done: false, key: '', email: '' },
  token: '',
  loadingVerification: false,
  loadingConfirmation: false,
  verificationError: null,
  confirmationError: null
};

@Component({
  selector: 'elm-verification-form',
  templateUrl: './verification-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerificationFormComponent extends ComponentStore<State> {
  verificationResponse$ = this.select(state => state.verificationResponse);

  isVerified$ = this.verificationResponse$.pipe(map(response => response.done));

  authorizationToken$ = this.select(state => state.token);

  isConfirmed$ = this.authorizationToken$.pipe(truthy);

  loadingVerification$ = this.select(state => state.loadingVerification);

  loadingConfirmation$ = this.select(state => state.loadingConfirmation);

  verificationError$ = this.select(state => state.verificationError);

  confirmationError$ = this.select(state => state.confirmationError);

  setToken = this.updater<string>((state, token) => ({ ...state, token }));

  @Input() formGuid!: string;

  @Input() config!: FormsApi.FormVerification;

  @Output() readonly confirm = new EventEmitter<string>();

  constructor(private formsApi: FormsApiService) {
    super(initialState);
  }

  onVerify = this.effect<string>(data$ =>
    data$.pipe(
      tap(() => {
        this.patchState({
          loadingVerification: true,
          verificationError: null,
          confirmationError: null
        });
      }),
      exhaustMap(identityId => {
        return this.formsApi.verifyUser(this.formGuid, identityId).pipe(
          responseData,
          tapResponse(
            (data: FormsApi.FormVerificationResponse) => {
              this.patchState({
                verificationResponse: data,
                loadingVerification: false
              });
            },
            ({ error }) => {
              this.patchState({
                verificationError:
                  error.data?.identity?.[0] ||
                  'The provided identity is not valid.',
                loadingVerification: false
              });
            }
          )
        );
      })
    )
  );

  onConfirm = this.effect<string>(data$ =>
    data$.pipe(
      tap(() =>
        this.patchState({
          loadingConfirmation: true,
          verificationError: null,
          confirmationError: null
        })
      ),
      withLatestFrom(this.verificationResponse$),
      exhaustMap(([code, response]) => {
        return this.formsApi
          .confirmUserVerification(this.formGuid, code, response.key)
          .pipe(
            responseData,
            tapResponse(
              data => {
                const { token } = data;
                this.patchState({
                  verificationResponse: { done: false, key: '', email: '' },
                  loadingConfirmation: false,
                  token
                });
                this.confirm.emit(token);
              },
              ({ error }) => {
                this.patchState({
                  confirmationError: error.data.identity[0] || 'Invalid code',
                  loadingConfirmation: false
                });
              }
            )
          );
      })
    )
  );
}
