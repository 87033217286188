import { Platform } from '@angular/cdk/platform';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { covideoApiUrl, covideoLocalStorage } from '@element451-libs/config451';
import { CovideoApi } from '@element451-libs/models451';
import { Observable } from 'rxjs';
import { API451_CLIENT, ApiClient } from '../api-client';

@Injectable()
export class CovideoInterceptor implements HttpInterceptor {
  constructor(
    @Inject(API451_CLIENT) public api451Client: ApiClient,
    private platform: Platform
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.isCovideoApiRequest(request)) {
      request = this.authorizeCovideoRequest(request);
    }
    return next.handle(request);
  }

  private authorizeCovideoRequest(request: HttpRequest<any>) {
    const auth = this.getCovideoAuth();

    if (auth && auth.accessToken) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${auth.accessToken}`
        }
      });
    }

    return request;
  }

  private isCovideoApiRequest(request: HttpRequest<any>) {
    return request.url.startsWith(covideoApiUrl);
  }

  private getCovideoAuth() {
    if (this.platform.isBrowser) {
      const data = window.localStorage.getItem(covideoLocalStorage.key('auth'));
      let auth: CovideoApi.AuthSuccess;
      try {
        auth = JSON.parse(data);
      } catch {
        console.error('Failed to parse Covideo auth data');
      }
      return auth;
    }
    return null;
  }
}
