import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationsApiService, responseData } from '@element451-libs/api451';
import { cachedIn, mapToPayload } from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { UserData } from '../user-data/user-data.service';
import * as fromForms from './forms.actions';
import { FORMS_ACTIONS } from './forms.actions';
import { Forms } from './forms.service';

@Injectable()
export class FormsEffects {
  constructor(
    private actions$: Actions<fromForms.FormsAction>,
    private applications: ApplicationsApiService,
    private forms: Forms,
    private userData: UserData
  ) {}

  loadForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FORMS_ACTIONS.LOAD_FORM_REQUEST),
      cachedIn(this.forms.entities$),
      mapToPayload,
      concatMap(guid =>
        this.applications.getPublicForm(guid).pipe(
          responseData,
          withLatestFrom(this.userData.fieldNameSlugMappings$),
          map(
            ([form, mappings]) =>
              new fromForms.LoadFormSuccessAction({
                form,
                mappings
              })
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              new fromForms.LoadFormFailAction({
                guid,
                error: response.error.userMessage,
                response
              })
            )
          )
        )
      )
    )
  );
}
