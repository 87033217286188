import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationsApiService, responseData } from '@element451-libs/api451';
import { LetterRenderer } from '@element451-libs/letters451';
import { expectAllToExist, waitFor } from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, zip } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { AccountService } from '../account';
import * as fromDashboard from '../dashboard/dashboard.actions';
import { DASHBOARD_ACTIONS } from '../dashboard/dashboard.actions';
import { DashboardService } from '../dashboard/dashboard.service';
import { UserApplications } from '../user-applications';
import { UserData } from '../user-data';
import * as actions from './letters.actions';
import { LETTERS_ACTIONS } from './letters.actions';

@Injectable()
export class LettersEffects {
  constructor(
    private applicationsApi: ApplicationsApiService,
    private actions$: Actions<
      actions.LettersAction | fromDashboard.DashboardAction
    >,
    private userData: UserData,
    private account: AccountService,
    private userApplications: UserApplications,
    private dashboard: DashboardService,
    private letterRenderer: LetterRenderer
  ) {}

  onDashboardLoadLoadLetters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DASHBOARD_ACTIONS.LOAD_DASHBOARD_SUCCESS),
      withLatestFrom(this.dashboard.applicationHasDecision$),
      filter(([_, hasDecision]) => hasDecision),
      waitFor(this.userData.registrationId$),
      map(_ => new actions.LoadLettersRequestAction())
    )
  );

  loadLetters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LETTERS_ACTIONS.LOAD_LETTERS_REQUEST),
      withLatestFrom(
        this.userData.registrationId$,
        this.account.userId$,
        this.userApplications.selectedApplicationGuid$
      ),
      expectAllToExist,
      switchMap(([_, registrationId, userId, applicationGuid]) => {
        return this.getDependenciesInParallel(registrationId, userId).pipe(
          switchMap(({ letters, tokens }) =>
            this.letterRenderer.renderMultiple(
              letters,
              { applicationGuid },
              tokens
            )
          ),
          map(letters => new actions.LoadLettersSuccessAction(letters)),
          catchError(err => of(new actions.LoadLettersFailAction(err)))
        );
      })
    )
  );

  getDependenciesInParallel(registrationId: string, userId: string) {
    const letters$ = this.getLetters(registrationId);
    const tokens$ = this.getCustomTokens(registrationId);

    return zip(letters$, tokens$).pipe(
      map(([letters, tokens]) => ({ letters, tokens })),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 204) return of({ letters: [], tokens: [] });
        else throw err;
      })
    );
  }

  getLetters(registrationId: string) {
    return this.applicationsApi.getLetters(registrationId).pipe(
      tap(response => {
        if (!response) throw new HttpErrorResponse({ status: 204 });
      }),
      responseData
    );
  }

  getCustomTokens(registrationId: string) {
    return this.applicationsApi
      .getPersonalizedLetterTokens(registrationId)
      .pipe(
        responseData,
        map(tokens =>
          tokens.map(token => ({
            token: `[${token.slug}]`,
            value: token.content
          }))
        )
      );
  }
}
