import { SegmentsApi } from '../segments';

export interface Thread {
  guid: string;
  slug: string;
  _subdom: string;
  updated_at: string;
  created_at: string;
  user: ThreadUser;
  messages: ThreadMessage[];
  title: string;
  location: ThreadLocation;
  shared?: boolean;
  conversation_started_from_thread_guid?: string;
  lead_gen_submitted?: boolean;
}

export type ThreadDescription = Omit<Thread, 'messages'> & {
  latest_message?: Pick<ThreadMessage, 'answer'>;
};

export interface ThreadUser {
  is_anonymous: boolean;
  anonymous_guid?: string;
  user_id?: string;
  is_internal: boolean;
  info?: {
    first_name: string;
    last_name: string;
    middle_name?: string;
    preferred_name?: string;
    email: string;
  };
}

export interface ThreadMessage {
  uuid: string;
  user_query: string;
  follow_ups?: any[];
  lead_gen_follow_up?: any;
  handoff_button?: string;
  queries?: string[];
  answer?: string;
  images?: any[];
  videos?: any[];
  content?: any[];
  location: ThreadLocation;
  created_at: string;
  updated_at: string;
  temp_guid?: string;

  lead_gen_submitted?: boolean;
  lead_gen_submitted_at?: string;

  handoff_submitted?: boolean;
  handoff_submitted_at?: string;
}

export interface ThreadLocation {
  url?: string;
  timezone?: string;
  screen_size?: string;
  user_agent?: string;
}

export interface CreateThreadDto {
  message: string;
  location: ThreadLocation;
}

export interface AddMessageDto {
  message: string;
  location: ThreadLocation;
  guid: string;
}

export interface ListThreadsParams {
  limit: number;
  beforeDate?: string;
  query?: string;
  user_ids?: string[];

  form_submitted?: boolean;
  handed_off?: boolean;
}

export interface Video {
  title: string;
  channel: string;
  date: string;
  thumbnail: string;
  url: string;
  embedUrl?: string;
  description?: string;
}

export type ListThreadsFilters = Pick<
  ListThreadsParams,
  'user_ids' | 'handed_off' | 'form_submitted'
>;

export enum SocketEvents {
  // response events
  MessageAdded = 'message_added',
  ThreadCreated = 'thread_created',
  ThreadCreatedFromShare = 'thread_created_from_share',
  CapturedLeadOnThread = 'captured_lead_on_thread',
  ConversationStartedFromThread = 'conversation_started_from_thread',
  UpdatedMessageHandoff = 'updated_message_handoff',

  // request events
  AddMessage = 'add_message',
  CreateThread = 'create_thread',

  UserOnline = 'user_online',
  UserOffline = 'user_offline',

  AnswerChunk = 'answer_chunk',
  VideosChunk = 'videos_chunk',
  CitedSourcesChunk = 'cited_sources_chunk',
  FollowUpsChunk = 'follow_ups_chunk',
  AnswerFull = 'answer_full',
  StartedLoadingMetadata = 'started_loading_metadata',
  Searching = 'searching'
}

export const threadToThreadDescription = (
  thread: Thread
): ThreadDescription => {
  const latestMessage = thread.messages[thread.messages.length - 1];
  return {
    guid: thread.guid,
    slug: thread.slug,
    _subdom: thread._subdom,
    updated_at: thread.updated_at,
    created_at: thread.created_at,
    user: thread.user,
    title: thread.title,
    latest_message: {
      answer: latestMessage?.answer
    },
    location: thread.location
  };
};

export enum BoltSearchSettingsType {
  Settings = 'settings',
  ThreadStarter = 'thread_starter',
  LeadCaptureForm = 'lead_capture_form'
}

export interface ThreadStarterDto {
  guid: string;
  title: string;
  message: string;
  conditions: SegmentsApi.BrowserSegmentValueFilter | null;
  enabled: boolean;
  index_weight: number;
  updated_at: string;
  created_at: string;
}

export type CreateThreadStarterDto = Pick<
  ThreadStarterDto,
  'title' | 'message' | 'conditions'
>;

export type UpdateThreadStarterDto = Partial<
  Pick<
    ThreadStarterDto,
    'title' | 'message' | 'conditions' | 'enabled' | 'index_weight'
  >
>;

export interface BoltSearchConfigDto {
  name: string;
  primary_color: string;
  secondary_color: string;
  thread_starters: ThreadStarterDto[];
  form_url: string | null;
}

export interface BoltSearchSettingsDto {
  financial_aid_enabled: boolean;
  common_app_enabled: boolean;
  discovery_title: string;
}

export type UpdateBoltSearchSettingsDto = Partial<
  Pick<
    BoltSearchSettingsDto,
    'financial_aid_enabled' | 'common_app_enabled' | 'discovery_title'
  >
>;

export interface LeadCaptureFormDto {
  guid: string;
  title: string;
  conditions: SegmentsApi.BrowserSegmentValueFilter | null;
  form_guid: string;
  enabled: boolean;
  index_weight: number;
  updated_at: string;
  created_at: string;
}

export type CreateLeadCaptureFormDto = Pick<
  LeadCaptureFormDto,
  'title' | 'form_guid' | 'conditions'
>;

export type UpdateLeadCaptureFormDto = Partial<
  Pick<
    LeadCaptureFormDto,
    'title' | 'form_guid' | 'conditions' | 'enabled' | 'index_weight'
  >
>;

export const BOLT_DISCOVERY_PREFIX = 'bolt-discovery://';

export enum IntercommunicationMessage {
  Open = 'openBoltSearch',
  Close = 'closeBoltSearch',
  Ask = 'askBoltSearch',
  SetParentPageContext = 'setBoltSearchParentPageContext',
  UpdateSize = 'updateBoltSearchSize',
  OpenPreviousThreads = 'openBoltSearchPreviousThreads'
}
