import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ApolloQueryResult } from '@apollo/client/core';
import { Observable, tap } from 'rxjs';

export function catchApolloError<T, R>(
  resultFactory: (result: T | null) => R | undefined
) {
  return (source$: Observable<ApolloQueryResult<T>>) =>
    source$.pipe(
      tap((result: ApolloQueryResult<T>) => {
        if (result.errors && !resultFactory(result.data)) {
          const status = result.errors?.[0]?.extensions?.code as HttpStatusCode;
          throw new HttpErrorResponse({
            error: result.errors?.[0].message,
            status: status || 400
          });
        }
      })
    );
}
