import {
  GetRelatedWorkflowsForLabelsQuery,
  GetRelatedWorkflowsForLabelsQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export {
  GetRelatedWorkflowsForLabelsQuery,
  GetRelatedWorkflowsForLabelsQueryVariables
};

export const getRelatedWorkflowsForLabels = gql<
  GetRelatedWorkflowsForLabelsQuery,
  GetRelatedWorkflowsForLabelsQueryVariables
>`
  query GetRelatedWorkflowsForLabels($labelGuids: [String!]!) {
    getRelatedWorkflowsForLabels(labelGuids: $labelGuids) {
      guid
      name
      active
      labelGuid
    }
  }
`;
