import { Platform } from '@angular/cdk/platform';
import {
  ChangeDetectionStrategy,
  Component,
  makeStateKey,
  OnInit,
  TransferState
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { App451Api } from '@element451-libs/api451';
import { AnalyticsService } from '@element451-libs/utils451/analytics';
import { DocumentRef } from '@element451-libs/utils451/document';
import {
  DynamicThemingService,
  ElmThemeColors
} from '@element451-libs/utils451/dynamic-theming';
import { FaviconService } from '@element451-libs/utils451/favicon';
import { Google } from '@element451-libs/utils451/google-maps';
import { waitFor } from '@element451-libs/utils451/rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { AccountService, MessengerService, SiteService } from './+state';

const HAS_SET_STYLES_KEY = makeStateKey<boolean>('hasSetStyles');

@UntilDestroy()
@Component({
  selector: 'elm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  constructor(
    private messenger: MessengerService,
    private account: AccountService,
    private site: SiteService,
    private title: Title,
    private meta: Meta,
    private favicon: FaviconService,
    private transferState: TransferState,
    private analytics: AnalyticsService,
    private dynamicTheming: DynamicThemingService,
    private google: Google,
    private documentRef: DocumentRef,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.setup();
  }

  setup() {
    this.setupGoogleMaps();

    this.site.baseSettings$
      .pipe(waitFor(this.site.loaded$), take(1))
      .subscribe(config => this.setupSite(config));

    this.setupMessenger();
  }

  setupMessenger() {
    if (this.platform.isBrowser) {
      this.messenger.init();

      this.account.authorization$
        .pipe(untilDestroyed(this))
        .subscribe(token => this.messenger.authenticate(token));
    }
  }

  setupGoogleMaps() {
    if (this.platform.isBrowser) {
      this.google.initGoogleMaps(this.documentRef.head);
    }
  }

  setupSite({
    colors,
    title,
    description,
    googleTagManagerId,
    faviconIco,
    faviconPng
  }: {
    colors: App451Api.App451ConfigColors;
    title: string;
    description: string;
    googleTagManagerId: string;
    faviconIco: string;
    faviconPng: string;
  }) {
    this.title.setTitle(title);
    this.meta.addTag({ name: 'description', content: description });

    /**
     * we want to set stylesheets only once, on the server or the browser
     */
    if (!this.transferState.get(HAS_SET_STYLES_KEY, false)) {
      this.favicon.setFavicons({ faviconPng, faviconIco });

      const themeColors: ElmThemeColors = {
        primary: colors.primary,
        accent: colors.secondary,
        linkDefault: colors.link
      };

      this.dynamicTheming.setOrUpdateComponentsTheme(themeColors);
      this.dynamicTheming.setOrUpdateMaterialTheme(themeColors);

      this.transferState.set(HAS_SET_STYLES_KEY, true);
    }

    if (this.platform.isBrowser) {
      // Setup analytics
      this.analytics.setup(googleTagManagerId);
    }
  }
}
