import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ApiFile, DocumentsApi } from '@element451-libs/models451';
import { provideTranslocoScope, TranslocoPipe } from '@jsverse/transloco';
import { isArray } from 'lodash';
import {
  ElmUploadHintDirective,
  ElmUploadLabelDirective,
  ElmUploadPlaceholderSubtitleDirective,
  ElmUploadPlaceholderTitleDirective
} from './elm-upload-directives';
import {
  FileAdapter,
  FileAdapterFactory,
  UPLOAD_FILE_STATE
} from './file-adapter';
import { filterByExtension, isFileSizeValid } from './helpers';
import { translationsLoader } from './i18n';

export interface ElmUploadPickEvent {
  file: File | File[];
}

@Component({
  selector: 'elm-upload-sync',
  templateUrl: './elm-upload.component.html',
  styleUrls: ['./elm-upload.component.scss'],
  host: {
    class: 'elm-upload elm-upload-sync'
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ElmUploadSyncComponent)
    },
    provideTranslocoScope({
      scope: 'elmUpload',
      loader: translationsLoader
    }),
    TranslocoPipe
  ]
})
export class ElmUploadSyncComponent implements ControlValueAccessor {
  @ViewChild('fileTrigger', { static: true })
  fileTrigger: ElementRef<HTMLInputElement>;
  @ContentChild(ElmUploadLabelDirective) label: ElmUploadLabelDirective;
  @ContentChild(ElmUploadHintDirective) hint: ElmUploadHintDirective;
  @ContentChild(ElmUploadPlaceholderTitleDirective)
  placeholderTitle: ElmUploadPlaceholderTitleDirective;
  @ContentChild(ElmUploadPlaceholderSubtitleDirective)
  placeholderSubtitle: ElmUploadPlaceholderSubtitleDirective;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter<ElmUploadPickEvent>();

  @HostBinding('class.elm-upload-disabled')
  @Input()
  disabled: boolean;

  // list of extensions ['.jpg', '.png']
  @Input() accept: string[];

  @Input() multiple = false;

  get hasFiles() {
    return this.files?.length > 0;
  }

  get commaSeparatedAccept(): string {
    return isArray(this.accept) && this.accept.length
      ? this.accept.join(', ')
      : DocumentsApi.DOCUMENT_SUPPORTED_EXTENSIONS.join(', ');
  }

  dragover = false;
  files: FileAdapter[] = [];

  errors: string[] = [];

  @HostListener('dragover', ['$event'])
  onDragOver(event) {
    if (this.disabled) return;

    this.dragover = true;
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event) {
    if (this.disabled) return;

    this.dragover = false;
    event.preventDefault();
  }

  /**
   * On drop
   */
  @HostListener('drop', ['$event', '$event.dataTransfer.files'])
  onDrop(event: Event, files: FileList) {
    if (this.disabled) return;

    this.dragover = false;
    event.preventDefault();
    event.stopPropagation();
    this.onTouched();

    if (files) {
      const _files = Array.from(files);
      const validFiles = this.filterOutInvalidFiles(_files);
      this.handleFileChange(validFiles);
    }
  }

  @HostListener('click', ['$event.target.classList'])
  handleClick(list: DOMTokenList) {
    if (this.disabled) return;

    if (
      list instanceof DOMTokenList &&
      (list.contains('wrapper') || list.contains('files'))
    ) {
      this.openFilePicker();
      this.onTouched();
    }
  }

  openFilePicker() {
    this.fileTrigger.nativeElement.click();
  }

  /**
   * On native file pick
   */
  onFilesChange(fileList: FileList) {
    const oldFiles = this.files.map(file => file.file) as File[];
    const newFiles = Array.from(fileList);

    const validFiles = this.filterOutInvalidFiles(newFiles);
    const files = this.multiple ? [...oldFiles, ...validFiles] : validFiles;

    this.fileTrigger.nativeElement.value = ''; // reset native file
    this.handleFileChange(files);
  }

  private filterOutInvalidFiles(files: File[]): File[] {
    this.errors = [];

    const accept = this.accept?.length
      ? this.accept
      : DocumentsApi.DOCUMENT_SUPPORTED_EXTENSIONS;

    const allowedFilesByExtension = filterByExtension(files, accept);

    if (files.length > allowedFilesByExtension.length) {
      const extensions = accept.join(', ');
      const err = this.translate.transform('elmUpload.errors.extensions', {
        extensions
      });
      this.errors.push(err);
    }

    const filesWithValidSize = allowedFilesByExtension.filter(isFileSizeValid);

    if (filesWithValidSize.length < allowedFilesByExtension.length) {
      const err = this.translate.transform('elmUpload.errors.size');
      this.errors.push(err);
    }

    return filesWithValidSize;
  }

  // called on drop and on file picking
  handleFileChange(files: File[]) {
    if (files.length === 0) return;

    const adapters = files.map(file => {
      const adapter = this.fileAdapterFactory.create(file);
      // move them to finished state, since upload is sync, we want to allow users
      // to remove files immidiately
      adapter.state = UPLOAD_FILE_STATE.FINISHED;
      return adapter;
    });

    this.files = adapters;

    const _files = adapters.map(adapter => adapter.file);
    const changedFiles = this.multiple ? _files : _files[0];

    this.onChange(changedFiles);
    this.change.emit({ file: changedFiles });
  }

  private onChange = (file: File | File[]) => {};
  private onTouched = () => {};

  constructor(
    private cd: ChangeDetectorRef,
    private fileAdapterFactory: FileAdapterFactory,
    private translate: TranslocoPipe
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(value: File | File[] | ApiFile | ApiFile[] | any) {
    if (isArray(value)) {
      this.files = value.map(file => this.fileAdapterFactory.create(file));
    } else if (value) {
      this.files = [this.fileAdapterFactory.create(value)];
    } else {
      this.files = [];
    }

    this.cd.markForCheck();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  view(adapter: FileAdapter) {
    const file = adapter?.file as ApiFile;
    if (!file?.url) return;
    const url = decodeURIComponent(file.url);
    window.open(url, '_blank');
  }

  remove(adapter: FileAdapter) {
    const handler = this.multiple ? this._removeMultiple : this._removeSingle;
    handler(adapter);
  }

  private _removeSingle = () => {
    this.files = [];
    this.onChange(null);
    this.change.emit({ file: null });
  };

  private _removeMultiple = (adapter: FileAdapter) => {
    const filteredFileAdapters = this.files.filter(
      file => file.ufid !== adapter.ufid
    );
    this.files = filteredFileAdapters;

    const result = filteredFileAdapters.length
      ? (filteredFileAdapters.map(file => file.file) as File[])
      : null;

    this.onChange(result);
    this.change.emit({ file: result });
  };
}
