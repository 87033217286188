import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApplicationsApiService,
  responseData,
  responseError
} from '@element451-libs/api451';
import { ElmDialogService } from '@element451-libs/components451/dialog';
import { cached, mapToPayload, truthy } from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  map,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import {
  NotificationsOverlayService,
  ResendConfirmDialogComponent
} from '../../components';
import * as fromDashboard from '../dashboard/dashboard.actions';
import { DASHBOARD_ACTIONS } from '../dashboard/dashboard.actions';
import { UserApplications } from '../user-applications';
import * as fromUserInfoRequest from './user-info-requests.actions';
import { USER_INFO_REQUESTS_ACTIONS } from './user-info-requests.actions';
import { UserInfoRequestsService } from './user-info-requests.service';

@Injectable()
export class UserInfoRequestsEffects {
  constructor(
    private actions$: Actions<
      fromUserInfoRequest.UserInfoRequestsAction | fromDashboard.DashboardAction
    >,
    private applicationsApiService: ApplicationsApiService,
    private userApplications: UserApplications,
    private userInfoRequestsService: UserInfoRequestsService,
    private notificationsService: NotificationsOverlayService,
    private dialog: ElmDialogService,
    private store: Store<any>,
    private router: Router
  ) {}

  getAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_INFO_REQUESTS_ACTIONS.GET_ALL_REQUEST),
      cached(this.userInfoRequestsService.loaded$, this.store),
      mapToPayload,
      switchMap(({ registrationId }) =>
        this.applicationsApiService.getUserInfoRequests(registrationId).pipe(
          responseData,
          map(
            data =>
              new fromUserInfoRequest.GetAllInfoRequestsSuccessAction(data)
          ),
          catchError(err =>
            of(new fromUserInfoRequest.GetAllInfoRequestsFailAction(err))
          )
        )
      )
    )
  );

  getAllOnDashboardLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DASHBOARD_ACTIONS.LOAD_DASHBOARD_SUCCESS),
      withLatestFrom(this.userApplications.activeRegistrationId$),
      map(
        ([_, registrationId]) =>
          new fromUserInfoRequest.GetAllInfoRequestsRequestAction({
            registrationId
          })
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_INFO_REQUESTS_ACTIONS.CREATE_REQUEST),
      mapToPayload,
      withLatestFrom(this.userApplications.activeRegistrationId$),
      concatMap(([payload, registrationId]) =>
        this.applicationsApiService
          .createRecommendationRequest(registrationId, payload.data)
          .pipe(
            responseData,
            map(data => data.info_requests),
            map(
              requests =>
                new fromUserInfoRequest.CreateRequestSuccessAction(requests)
            ),
            catchError(err =>
              of(new fromUserInfoRequest.CreateRequestFailAction(err))
            )
          )
      )
    )
  );

  openResendDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_INFO_REQUESTS_ACTIONS.OPEN_RESEND_CONFIRM_DIALOG),
      mapToPayload,
      exhaustMap(payload =>
        this.dialog
          .open(ResendConfirmDialogComponent, { data: payload.infoRequest })
          .pipe(
            truthy,
            map(
              _ =>
                new fromUserInfoRequest.ResendRequestRequestAction({
                  infoRequestId: payload.infoRequest._id
                })
            )
          )
      )
    )
  );

  resend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_INFO_REQUESTS_ACTIONS.RESEND_REQUEST),
      mapToPayload,
      withLatestFrom(this.userApplications.activeRegistrationId$),
      concatMap(([payload, registrationId]) =>
        this.applicationsApiService
          .resendUserInfoRequest(registrationId, payload.infoRequestId)
          .pipe(
            responseData,
            map(
              data => new fromUserInfoRequest.ResendRequestSuccessAction(data)
            ),
            catchError(err =>
              of(new fromUserInfoRequest.ResendRequestFailAction(err))
            )
          )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_INFO_REQUESTS_ACTIONS.DELETE_REQUEST),
      mapToPayload,
      concatMap(payload =>
        this.applicationsApiService.deleteUserInfoRequest(payload).pipe(
          responseData,
          map(
            data => new fromUserInfoRequest.DeleteRequestSuccessAction(payload)
          ),
          catchError(err =>
            of(new fromUserInfoRequest.DeleteRequestFailAction(err))
          )
        )
      )
    )
  );

  createSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(USER_INFO_REQUESTS_ACTIONS.CREATE_SUCCESS),
        withLatestFrom(this.userApplications.activeRegistrationId$),
        tap(([_, registrationId]) =>
          this.router.navigateByUrl(
            `dashboard/${registrationId}/recommendations`
          )
        )
      ),
    { dispatch: false }
  );

  requestFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          USER_INFO_REQUESTS_ACTIONS.GET_ALL_FAIL,
          USER_INFO_REQUESTS_ACTIONS.CREATE_FAIL,
          USER_INFO_REQUESTS_ACTIONS.DELETE_FAIL,
          USER_INFO_REQUESTS_ACTIONS.RESEND_FAIL
        ),
        mapToPayload,
        responseError,
        tap(error =>
          this.notificationsService.open({
            type: 'error',
            message: error.userMessage
          })
        )
      ),
    { dispatch: false }
  );
}
