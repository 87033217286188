<div editableGroup>
  <ng-content select="elm-pg-overlay"></ng-content>
  <div class="elm-pg-above-overlay">
    <swiper-container
      class="swiper-container"
      [elmSwiper]="config"
      (elmSwiperInit)="recalculateBounds()"
      (swiperresize)="recalculateBounds()"
      (swiperslidechange)="onIndexChange()"
    >
      @for (slide of slides; track slide.url) {
        <swiper-slide>
          <img [src]="getUrl(slide)" [attr.data-src]="slide.url" [alt]="getAlt(slide)" (click)="onImageClick($event)" />
        </swiper-slide>
      }
    </swiper-container>

    <div class="swiper-caption" *ngIf="isLarge && currentSlide">
      <p
        class="lum-body-1 elm-dynamic-wysiwyg-link-color-wrapper"
        (click)="stopAutoplay()"
        (focusout)="startAutoplay()"
        editable="slides[{{ currentSlideIndex }}].caption"
        [innerHTML]="currentSlide.caption"
      ></p>
    </div>
  </div>
</div>
