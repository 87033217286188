import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ElmSwiperDirective } from '@element451-libs/swiper';
import { PipesModule } from '@element451-libs/utils451/pipes';
import { BtnModule } from '../btn';
import { Page451EditorCoreModule } from '../editor';
import { Page451ComponentsSharedModule } from '../shared';
import { SocialFeedComponent } from './social-feed.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    Page451ComponentsSharedModule,
    Page451EditorCoreModule.forChild(),
    ElmSwiperDirective,
    BtnModule,
    PipesModule
  ],
  exports: [SocialFeedComponent],
  declarations: [SocialFeedComponent]
})
export class SocialFeedModule {}
