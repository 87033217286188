import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export interface ValidationError {
  controlName: string;
  errorName: string;
  errorValue: any;
}

export interface FormGroupControls {
  [key: string]: AbstractControl;
}

export function getFormValidationErrors(
  controls: FormGroupControls
): ValidationError[] {
  let errors: ValidationError[] = [];
  Object.keys(controls).forEach(key => {
    const control = controls[key];
    if (control instanceof UntypedFormGroup) {
      errors = errors.concat(getFormValidationErrors(control.controls));
    }
    const controlErrors: ValidationErrors = controls[key].errors;
    if (controlErrors !== null) {
      Object.keys(controlErrors).forEach(keyError => {
        errors.push({
          controlName: key,
          errorName: keyError,
          errorValue: controlErrors[keyError]
        });
      });
    }
  });
  return errors;
}

export const honeyPotErrorName = 'honeyPotTouched';

export function isHoneyPotError(error: ValidationError) {
  return error.errorName === honeyPotErrorName;
}
