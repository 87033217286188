import {
  mapsApiKey,
  oAuthFacebookAppId,
  oAuthGoogleClientId,
  oAuthTwitterApiKey
} from '@element451-libs/config451';

export const environment = {
  production: true,
  mapsApiKey,
  oAuthGoogleClientId,
  oAuthFacebookAppId,
  oAuthTwitterApiKey
};
