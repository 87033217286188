import {
  SearchUsersQuery,
  SearchUsersQueryVariables,
  User
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { User };

export const searchUsersQuery = gql<
  SearchUsersQuery,
  SearchUsersQueryVariables
>`
  query SearchUsers(
    $text: String
    $page: Int
    $limit: Int
    $filter: SearchUsersInputFilter
    $sort: String
  ) {
    searchUsers(
      input: {
        query: $text
        page: $page
        limit: $limit
        filter: $filter
        sort: $sort
      }
    ) {
      users {
        id
        email
        first_name
        last_name
        middle_name
        preferred_name
        former_last_name
        territory
        owner
        gender
        race
        citizenship
        custom
        calculated
        education
        milestones
        activities {
          name
          position
          years_held
          hours_week
        }
        identities {
          type
          value
        }
        applications {
          guid
          major
          term
          degree
          registration_id
          registered_at
          submitted_time
          decision_released_at
          final_decision_at
        }
        addresses {
          type
          country
          street_1
          street_2
          street_3
          city
          state
          zipcode
          is_mailing
        }
        phone {
          type
          number
          country_alpha_2
          index_weight
          country_code
          carrier_name
          international
          valid
          formatted
        }
        date_of_birth
        updated_at
        created_at
        labels
        type
        profile_type
        active
        location: home_address {
          city
          country
          state
          street_1
          zipcode
        }
      }
      hasNextPage
      hasPrevPage
    }
  }
`;
