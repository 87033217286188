import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on
} from '@ngrx/store';

import { ApplicationsApi, Forms451Api } from '@element451-libs/models451';

import { createEntityAdapter, EntityState, Update } from '@ngrx/entity';

import { sortCollection } from '@element451-libs/utils451/helpers';
import { fromRouter } from '@element451-libs/utils451/router';
import { selectApp } from '../app.feature';
import { getUpdatedValue, getUserDataKey } from '../user-data/helpers';
import * as supplementalFormsActions from './supplemental-forms.actions';

export interface SupplementalFormsState
  extends EntityState<ApplicationsApi.PublicSupplementalFormsStep> {
  singleLoading: boolean;
  listLoading: boolean;
  listLoaded: boolean;
  list: ApplicationsApi.PublicSupplementalFormsStepListItem[];
}

const selectId = (
  supplementalForm: ApplicationsApi.PublicSupplementalFormsStep
) => supplementalForm.item_id;

const sortSteps =
  sortCollection<ApplicationsApi.PublicSupplementalFormsStepListItem>(
    (a, b) => a.index_weight - b.index_weight
  );

const adapter =
  createEntityAdapter<ApplicationsApi.PublicSupplementalFormsStep>({
    selectId,
    sortComparer: false
  });

const initialState: SupplementalFormsState = adapter.getInitialState({
  listLoading: false,
  listLoaded: false,
  singleLoading: false,
  list: []
});

export const supplementalFormsReducer = createReducer(
  initialState,
  on(supplementalFormsActions.getSupplementalForms, state => ({
    ...initialState,
    singleLoading: state.singleLoading,
    listLoading: true,
    listLoaded: false
  })),
  on(
    supplementalFormsActions.getSupplementalFormsSuccess,
    (state, { supplementalForms }) => ({
      ...state,
      list: supplementalForms,
      listLoading: false,
      listLoaded: true
    })
  ),
  on(supplementalFormsActions.getSupplementalFormsFail, state => ({
    ...state,
    listLoading: false,
    listLoaded: false
  })),
  on(supplementalFormsActions.getSupplementalForm, (state, { itemId }) => {
    return state.entities[itemId] ? state : { ...state, singleLoading: true };
  }),
  on(
    supplementalFormsActions.getSupplementalFormSuccess,
    (state, { supplementalForm }) =>
      adapter.addOne(supplementalForm, {
        ...state,
        singleLoading: false
      })
  ),
  on(supplementalFormsActions.getSupplementalFormFail, state => ({
    ...state,
    singleLoading: false
  })),
  on(
    supplementalFormsActions.saveSupplementalFormSuccess,
    (state, { answer }) => {
      const Done = ApplicationsApi.SupplementalFormStatus.Done;

      const { info, ...rest } = answer;

      const form = state.entities[rest.app_item_id].field;

      const formFields = form.form_data.fields;

      const user_data = info.reduce((acc, field) => {
        const fieldModel = formFields.find(f => f.name === field.name);

        const key = getUserDataKey(fieldModel);

        const value = getUpdatedValue(field);

        acc[key] = Forms451Api.isMultiFileType(fieldModel.type)
          ? { files: value }
          : value;

        return acc;
      }, {});

      const update: Update<ApplicationsApi.PublicSupplementalFormsStep> = {
        id: answer.app_item_id,
        changes: {
          answer: rest,
          status: Done,
          field: { ...form, user_data }
        }
      };

      // Update status in listing
      const list = state.list.map(item =>
        item.item_id === answer.app_item_id ? { ...item, status: Done } : item
      );

      // update expanded form
      return adapter.updateOne(update, { ...state, list });
    }
  )
);

export const supplementalFormsFeature = 'supplementalForms';

const _selectSupplementalFormsState =
  createFeatureSelector<SupplementalFormsState>(supplementalFormsFeature);

export const selectSupplementalFormsState = createSelector(
  selectApp,
  _selectSupplementalFormsState
);

export const selectListLoading = createSelector(
  selectSupplementalFormsState,
  state => state.listLoading
);

export const selectListLoaded = createSelector(
  selectSupplementalFormsState,
  state => state.listLoaded
);

export const selectSingleLoading = createSelector(
  selectSupplementalFormsState,
  state => state.singleLoading
);

export const selectList = createSelector(selectSupplementalFormsState, state =>
  sortSteps(state.list)
);

export const selectTotal = createSelector(selectList, list => list.length);

export const { selectAll, selectEntities } = adapter.getSelectors(
  selectSupplementalFormsState
);

export const selectSelectedStepId = createSelector(
  fromRouter.selectRouterParams,
  params => params?.['supplementalStepId'] as string
);

export const selectSelectedStep = createSelector(
  selectEntities,
  selectSelectedStepId,
  (entities, selectedGuid) => (selectedGuid ? entities[selectedGuid] : null)
);
