import { Action } from '@ngrx/store';

import { ApplicationsApi } from '@element451-libs/api451';
import { CacheableAction } from '@element451-libs/utils451/rxjs';

import { Dashboard } from './dashboard.models';

export const enum DASHBOARD_ACTIONS {
  LOAD_DASHBOARD_REQUEST = '[Dashboard] Request Load Dashboard',
  LOAD_DASHBOARD_SUCCESS = '[Dashboard] Success Load Dashboard',
  LOAD_DASHBOARD_FAIL = '[Dashboard] Fail Load Dashboard',

  APPLICATION_COMPLETED = '[Dashboard] Application Completed',
  APPLICATION_SUBMITTED = '[Dashboard] Application Submitted',
  APPLICATION_PAID = '[Dashboard] Application Paid',

  DEPOSIT_PAID = '[Dashboard] Deposit Paid',

  OPENED_WELCOME_PAGE = '[Dashboard] Opened Welcome Page',
  CLOSED_WELCOME_PAGE = '[Dashboard] Closed Welcome Page',

  HAS_UNSAVED_FORM_DATA = '[Dashboard] Has Unsaved Form Data',

  SWITCH_APPLICATION = '[Dashboard] Switch Application',
  GO_TO_FIRST_STEP = '[Dashboard] Go to First Step',

  DECLINE_OFFER_REQUEST = '[Dashboard] Decline Offer Request',
  DECLINE_OFFER_SUCCESS = '[Dashboard] Decline Offer Success',
  DECLINE_OFFER_FAIL = '[Dashboard] Decline Offer Fail'
}

export class ApplicationCompletedAction implements Action {
  readonly type = DASHBOARD_ACTIONS.APPLICATION_COMPLETED;
  constructor(public payload: { registrationId: string }) {}
}

export class ApplicationSubmittedAction implements Action {
  readonly type = DASHBOARD_ACTIONS.APPLICATION_SUBMITTED;

  constructor(public payload: { registrationId: string }) {}
}

export class ApplicationPaidAction implements Action {
  readonly type = DASHBOARD_ACTIONS.APPLICATION_PAID;

  constructor(public payload: { registrationId: string }) {}
}

export class DepositPaidAction implements Action {
  readonly type = DASHBOARD_ACTIONS.DEPOSIT_PAID;

  constructor(public payload: { registrationId: string }) {}
}
/**
 * LOAD DASHBOARD
 */

export class LoadDashboardRequestAction extends CacheableAction {
  readonly type = DASHBOARD_ACTIONS.LOAD_DASHBOARD_REQUEST;

  constructor(public payload: string) {
    super();
  }
}

export class LoadDashboardSuccessAction implements Action {
  readonly type = DASHBOARD_ACTIONS.LOAD_DASHBOARD_SUCCESS;

  constructor(
    public payload: { normalized: Dashboard; raw: ApplicationsApi.Dashboard }
  ) {}
}

export class LoadDashboardFailAction implements Action {
  readonly type = DASHBOARD_ACTIONS.LOAD_DASHBOARD_FAIL;

  constructor(public payload: any) {}
}

export class OpenedWelcomePageAction implements Action {
  readonly type = DASHBOARD_ACTIONS.OPENED_WELCOME_PAGE;
}

export class ClosedWelcomePageAction implements Action {
  readonly type = DASHBOARD_ACTIONS.CLOSED_WELCOME_PAGE;
}

export class HasUnsavedFormDataAction implements Action {
  readonly type = DASHBOARD_ACTIONS.HAS_UNSAVED_FORM_DATA;

  constructor(public payload: boolean) {}
}

export class SwitchApplicationAction implements Action {
  readonly type = DASHBOARD_ACTIONS.SWITCH_APPLICATION;

  constructor(public payload: { registrationId: string }) {}
}

export class GoToFirstStepAction implements Action {
  readonly type = DASHBOARD_ACTIONS.GO_TO_FIRST_STEP;
}

export class DeclineOfferRequestAction implements Action {
  readonly type = DASHBOARD_ACTIONS.DECLINE_OFFER_REQUEST;
}

export class DeclineOfferSuccessAction implements Action {
  readonly type = DASHBOARD_ACTIONS.DECLINE_OFFER_SUCCESS;

  constructor(public payload: { registrationId: string }) {}
}

export class DeclineOfferFailAction implements Action {
  readonly type = DASHBOARD_ACTIONS.DECLINE_OFFER_FAIL;

  constructor(public payload: { error: any }) {}
}

export type DashboardAction =
  | ApplicationCompletedAction
  | ApplicationSubmittedAction
  | ApplicationPaidAction
  | DepositPaidAction
  | OpenedWelcomePageAction
  | ClosedWelcomePageAction
  | SwitchApplicationAction
  | LoadDashboardRequestAction
  | LoadDashboardSuccessAction
  | LoadDashboardFailAction
  | HasUnsavedFormDataAction
  | GoToFirstStepAction
  | DeclineOfferRequestAction
  | DeclineOfferSuccessAction
  | DeclineOfferFailAction;
