import {
  TasksApi_CountTasksQuery as CountTasks,
  TasksApi_CountTasksQueryVariables as CountTasksVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { CountTasks, CountTasksVariables };

export const countTasks = gql<CountTasks, CountTasksVariables>`
  query TasksApi_CountTasks(
    $q: String!
    $filters: TasksFiltersInput!
    $queue: String
  ) {
    countTasks(input: { q: $q, filters: $filters, queue: $queue })
  }
`;
