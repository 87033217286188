import {
  OrganizationsSegmentsQuery,
  OrganizationsSegmentsQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const organizationsSegmentsQuery = gql<
  OrganizationsSegmentsQuery,
  OrganizationsSegmentsQueryVariables
>`
  query OrganizationsSegments($params: OrganizationsSegmentsParamsInput!) {
    countOrganizationsSegments(input: $params)
    organizationsSegments(input: $params) {
      guid
      type
      name
      slug
      folder
      tags
      owner
      evaluated
      output
      owner_collection
      is_calculated
      created_at
      updated_at
      users
      organizations
    }
  }
`;
