import { Platform } from '@angular/cdk/platform';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import Swiper from 'swiper';
import { SwiperContainer } from 'swiper/element';

type Config = Partial<SwiperContainer>;

@Directive({
  selector: 'swiper-container[elmSwiper]',
  standalone: true,
  host: { class: 'swiper elm-swiper', init: 'false' }
})
export class ElmSwiperDirective implements AfterViewInit, OnDestroy {
  elementRef = inject(ElementRef<SwiperContainer>);
  platform = inject(Platform);

  element!: SwiperContainer;

  private _config!: Config;

  @Input('elmSwiper') set config(config: Config) {
    this._config = config;
  }

  @Output() elmSwiperInit = new EventEmitter<Swiper>();

  ngAfterViewInit() {
    if (this.platform.isBrowser) {
      this.element = this.elementRef.nativeElement;
      this.applyConfig(this._config);
      this.element.initialize();
      this.elmSwiperInit.emit(this.element.swiper);
    }
  }

  private applyConfig(config: Config) {
    if (config) {
      Object.assign(this.element, config);
    }
  }

  update(params: Config) {
    this.applyConfig(params);
    setTimeout(() => {
      this.element.swiper.update();
    });
  }

  ngOnDestroy(): void {
    if (this.element?.swiper && !this.element?.swiper?.destroyed) {
      this.element.swiper.destroy();
    }
  }
}
