import { TasksApi_GetQueueStatsQuery as GetQueueStats } from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const getQueueStats = gql<GetQueueStats, void>`
  query TasksApi_GetQueueStats {
    queueStats {
      my
      all
    }
  }
`;
