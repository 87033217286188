import { Platform } from '@angular/cdk/platform';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import * as moment from 'moment';
import { EventBusService } from '../../../../event-bus';
import { DynamicFieldModel } from '../../../../models';
import { FieldConfigDirective } from '../../../shared/field-config.directive';

@Component({
  selector: 'lum-df-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatepickerComponent
  extends FieldConfigDirective<DynamicFieldModel>
  implements OnInit
{
  startAt: moment.Moment | null;

  isMobile = this.platform.IOS || this.platform.ANDROID;

  constructor(
    eventBus: EventBusService,
    public platform: Platform
  ) {
    super(eventBus);
  }

  ngOnInit() {
    if (this.model.placeholder) {
      try {
        const exactDate = moment(this.model.placeholder);
        if (exactDate.isValid()) {
          this.startAt = exactDate;
          return;
        }

        const offset = decodeOffset(this.model.placeholder);

        if (
          !offset?.amount ||
          !periods.has(offset.period) ||
          !units.has(offset.unit)
        ) {
          console.error('Invalid offset', this.model.placeholder);
          return;
        }

        const date =
          offset.period === Period.Before
            ? moment().subtract(offset.amount, offset.unit)
            : moment().add(offset.amount, offset.unit);

        this.startAt = date.isValid() ? date : null;
      } catch (e) {
        console.error(e);
      }
    }
  }
}

enum Unit {
  Hour = 'h',
  Day = 'd',
  Week = 'w',
  Month = 'M',
  Year = 'y'
}

interface Offset {
  period: string;
  amount: number;
  unit: Unit;
}

enum Period {
  Before = '-',
  After = '+'
}

const periods = new Set<string>([Period.Before, Period.After]);

const units = new Set<string>([
  Unit.Hour,
  Unit.Day,
  Unit.Week,
  Unit.Month,
  Unit.Year
]);

const decodeOffset = (offset: string): Offset => ({
  period: offset.slice(0, 1),
  amount: parseInt(offset.slice(1, -1), 10),
  unit: offset.slice(-1) as Unit
});
