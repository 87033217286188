import { isPlatformBrowser } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Inject,
  Input,
  OnChanges,
  PLATFORM_ID,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Google } from '@element451-libs/utils451/google-maps';
import { filter, first } from 'rxjs/operators';
import { GRID, Grid, mixinTheme, Page451Component, Theme } from '../core';
import {
  Page451EditableGroupService,
  PAGE_COMPONENT,
  PAGE_CONFIG
} from '../editor';
import { SocialNetwork } from '../social-networks';
import { mapConfigFactory } from './map.config';
import { IPgMap, IPgMapElements, MapTheme } from './map.interface';
import { PAGE451_MAP_THEMES, PAGE451_MAP_THEMES_PROVIDER } from './theme';

const defaultMap = {
  zoom: 12,
  center: {
    lat: 40.692523,
    lng: -73.956043
  }
};

const infoTextBlockClasses = {
  dark: 'lum-fg-light-gray',
  light: 'lum-fg-normal-gray'
};

const titleClasses = {
  dark: 'lum-fg-white',
  light: 'lum-fg-normal-gray'
};

export class MapComponentBase {
  constructor(
    public renderer: Renderer2,
    public elementRef: ElementRef
  ) {}
}
export const _MapComponentBase = mixinTheme(MapComponentBase, 'light');

const isValidMarker = (marker: google.maps.LatLngLiteral) =>
  !!marker &&
  marker.lat !== null &&
  !isNaN(marker.lat) &&
  marker.lng !== null &&
  !isNaN(marker.lng);

@Component({
  selector: 'elm-pg-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    PAGE451_MAP_THEMES_PROVIDER,
    { provide: PAGE_CONFIG, useFactory: mapConfigFactory },
    { provide: PAGE_COMPONENT, useExisting: forwardRef(() => MapComponent) },
    Page451EditableGroupService
  ]
})
export class MapComponent
  extends _MapComponentBase
  implements Page451Component, IPgMap, AfterContentInit, OnChanges
{
  private _markerRef: google.maps.Marker;

  private _previousNonCustomTheme: Theme;

  private _map: google.maps.Map;

  @ViewChild('map', { static: true }) mapRef: ElementRef;

  @Input() pageGuid: string;

  @Input() theme: Theme;

  @Input() blockColor: string;

  @Input() mapTheme: MapTheme = 'standard';

  @Input() marker: google.maps.LatLngLiteral;

  @Input() zoom: number = defaultMap.zoom;

  @Input() info: string;

  @Input() title: string;

  @Input() networksTitle: string;

  @Input() showIcons: boolean;

  @Input()
  elements: IPgMapElements = {
    socialIcons: true
  };

  @Input() iconsColor: string;

  @Input() iconsTextColor: string;

  @Input() socialNetworks: SocialNetwork[];

  get currentTheme(): Theme {
    return this.theme === 'dark' || this.theme === 'light'
      ? this.theme
      : this._previousNonCustomTheme;
  }

  get infoTextClasses(): string {
    return `${infoTextBlockClasses[this.currentTheme]}`;
  }

  get titleClasses(): string {
    return `${titleClasses[this.currentTheme]}`;
  }

  constructor(
    renderer: Renderer2,
    elementRef: ElementRef,
    private _media: MediaObserver,
    private _google: Google,
    @Inject(PAGE451_MAP_THEMES) private themes: object,
    @Inject(GRID) public _GRID: Grid,
    @Inject(PLATFORM_ID) private platformId
  ) {
    super(renderer, elementRef);
  }

  ngAfterContentInit() {
    this._google.isActive
      .pipe(
        first(Boolean),
        filter(_ => this.canGoogleMap)
      )
      .subscribe(_ => (this._map = this._createMap()));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['theme']) this._handleThemeChange(changes);
    if (!this.canGoogleMap || !this._map) return;

    this._map.setOptions(this._buildUpdateOptionsFromChanges(changes));

    if (
      changes['marker'] &&
      changes['marker'].currentValue &&
      isValidMarker(changes['marker'].currentValue)
    ) {
      this._createMarker(this.marker, this._map);
      this._setCenter(this.marker, this._map);
    }
  }

  private _handleThemeChange(changes: SimpleChanges) {
    const newTheme = changes['theme'].currentValue;

    if (newTheme === 'dark' || newTheme === 'light') {
      this._previousNonCustomTheme = newTheme;
    }
  }

  private _createMap(): google.maps.Map {
    const map = new google.maps.Map(this.mapRef.nativeElement, {
      center: defaultMap.center,
      styles: this._styles,
      zoom: this.zoom || defaultMap.zoom,
      mapTypeControl: false,
      scrollwheel: false
    });

    if (isValidMarker(this.marker)) {
      this._createMarker(this.marker, map);
      this._setCenter(this.marker, map);
    }

    return map;
  }

  private get canGoogleMap(): boolean {
    if (!isPlatformBrowser(this.platformId)) return false;
    return !!(window && window['google']);
  }

  private get _styles(): google.maps.MapTypeStyle[] {
    return this.themes[this.mapTheme] || this.themes['standard'];
  }

  private _buildUpdateOptionsFromChanges(changes: SimpleChanges): object {
    let options = {};

    if (changes['mapTheme'] && changes['mapTheme'].currentValue) {
      options = {
        styles: this._styles
      };
    }

    if (changes['zoom'] && changes['zoom'].currentValue) {
      options = {
        ...options,
        zoom: this.zoom
      };
    }

    return options;
  }

  private _createMarker(
    position: google.maps.LatLngLiteral,
    map: google.maps.Map
  ): void {
    if (this._markerRef) {
      this._markerRef.setMap(null);
    }

    this._markerRef = new google.maps.Marker({
      position,
      map
    });
  }

  private _setCenter(center: google.maps.LatLngLiteral, map: google.maps.Map) {
    map.setCenter(center);
    if (this._media.isActive('gt-xs')) {
      map.panBy(200, 0);
    }
  }
}
