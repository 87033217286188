import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ElmSwiperDirective } from '@element451-libs/swiper';
import { Page451EditorCoreModule } from '../editor';
import { CarouselComponent } from './carousel.component';

@NgModule({
  imports: [CommonModule, Page451EditorCoreModule, ElmSwiperDirective],
  exports: [CarouselComponent],
  declarations: [CarouselComponent]
})
export class CarouselModule {}
