import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  Output,
  Renderer2
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import * as moment from 'moment';
import { IPgButton } from '../btn';
import {
  Background,
  GRID,
  mixinBackground,
  mixinPadding,
  mixinResponsive,
  mixinTheme,
  PaddingType,
  Page451Component,
  Theme
} from '../core';
import {
  Page451EditableGroupService,
  PAGE_COMPONENT,
  PAGE_CONFIG
} from '../editor';
import { socialFeedConfigFactory, swiperConfig } from './social-feed.config';
import {
  IPgSocialFeed,
  IPgSocialFeedElements,
  IPgSocialFeedItem,
  SocialFeedType
} from './social-feed.interface';

export class SocialFeedComponentBase {
  constructor(
    public renderer: Renderer2,
    public elementRef: ElementRef,
    public media: MediaObserver,
    public cd: ChangeDetectorRef
  ) {}
}
export const _SocialFeedComponentBase = mixinBackground(
  mixinResponsive(
    mixinPadding(mixinTheme(SocialFeedComponentBase, 'light'), {
      top: '80px',
      bottom: '80px'
    })
  )
);

@Component({
  selector: 'elm-pg-social-feed',
  templateUrl: './social-feed.component.html',
  styleUrls: ['./social-feed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: PAGE_CONFIG, useFactory: socialFeedConfigFactory },
    {
      provide: PAGE_COMPONENT,
      useExisting: forwardRef(() => SocialFeedComponent)
    },
    Page451EditableGroupService
  ]
})
export class SocialFeedComponent
  extends _SocialFeedComponentBase
  implements Page451Component, IPgSocialFeed
{
  @Input() pageGuid: string;
  @Input() title: string;
  @Input() description?: string;
  @Input() feedType: SocialFeedType = 'swipe';
  @Input() button: IPgButton;

  @Input() config: any = swiperConfig;
  @Input() background: Background;
  @Input() padding: PaddingType;
  @Input() theme: Theme;
  @Input()
  elements: IPgSocialFeedElements = {
    title: true,
    description: true
  };

  @Input()
  set items(items: IPgSocialFeedItem[]) {
    this._items = items;
  }
  get items() {
    return this._items;
  }

  @Input() iconsInactiveColor: string;
  @Input() iconsActiveColor: string;
  @Input() iconsActiveBgColor: string;

  @Output() filterChange = new EventEmitter<string>();

  @Output() loadMore = new EventEmitter();

  @HostBinding('class.elm-layout-small') isLtMd;

  @HostBinding('class.elm-pg-theme-background')
  get isBackgroundTheme(): boolean {
    return this.background && !!this.background.value;
  }

  private _items: IPgSocialFeedItem[];

  filterOptions = [
    {
      label: 'All',
      network: null
    },
    {
      label: 'Facebook',
      network: 'facebook'
    },
    {
      label: 'X (Twitter)',
      network: 'twitter'
    }
  ];

  activeFilter = null;

  get columns(): Array<IPgSocialFeedItem[]> {
    const columns = [];
    let mod = 1;

    if (this.isGtMd) {
      mod = 3;
    } else if (this.isMd) {
      mod = 2;
    }

    this.items.forEach((item, index) => {
      const col = index % mod;
      if (columns[col]) {
        columns[col].push(item);
      } else {
        columns[col] = [item];
      }
    });

    return columns;
  }

  constructor(
    renderer: Renderer2,
    elementRef: ElementRef,
    media: MediaObserver,
    cd: ChangeDetectorRef,
    @Inject(GRID) public _GRID
  ) {
    super(renderer, elementRef, media, cd);
  }

  backgroundImage(url: string): string {
    return `url(${url})`;
  }

  onFilterChange(network: string) {
    this.activeFilter = network;
    this.filterChange.emit(network);
  }

  onLoadMore() {
    this.loadMore.emit();
  }

  timeAgo(value: string) {
    return moment(value).fromNow(true);
  }

  get colLayout(): string {
    if (this.isGtMd) {
      return this._GRID.col[4];
    } else if (this.isMd) {
      return this._GRID.col[6];
    }

    return this._GRID.col[12];
  }

  trackByColumn(index, items: IPgSocialFeedItem[]) {
    return items.map(item => item.postUrl).join('-');
  }

  trackByItem(index, item: IPgSocialFeedItem) {
    return item ? item.postUrl : undefined;
  }
}
