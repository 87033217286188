import {
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  Provider,
  SkipSelf
} from '@angular/core';
import { throwIfAlreadyLoaded } from '@element451-libs/common451';
import { AdminsApiModule } from './admins';
import { AIApiModule } from './ai';
import { ApiKeysApiModule } from './api-keys';
import { ApplicationsApiModule } from './applications';
import { ApplicationsMessagesApiModule } from './applications-messages';
import { AppointmentsApiModule } from './appointments';
import { ArticlesApiModule } from './articles';
import { AuthApiModule } from './auth';
import { BoltSearchApiModule } from './bolt-search';
import { Campaigns451ApiModule } from './campaigns451';
import { CampusesApiModule } from './campuses';
import { CeebApiModule } from './ceeb';
import { ChecklistsApiModule } from './checklists';
import { ConditionsApiModule } from './conditions';
import { ConnectorsApiModule } from './connectors';
import { ContentBlocksApiModule } from './content-blocks';
import { ConversationViewsApiModule } from './conversation-views';
import { Conversations451ApiModule } from './conversations451';
import { CopilotApiModule } from './copilot';
import { Api451CoreModule } from './core';
import { CouponsApiModule } from './coupons';
import { CoursesApiModule } from './courses';
import { CovideoApiModule } from './covideo';
import { DataApiModule } from './data';
import { DataFieldsApiModule } from './data-fields';
import { DataSourcesApiModule } from './data-sources';
import { Decision451ApiModule } from './decision451';
import { DeduplicationApiModule } from './deduplication';
import { DegreesApiModule } from './degrees';
import { DepositsApiModule } from './deposits';
import { DocumentsApiModule } from './documents';
import { DomainsApiModule } from './domains';
import { EmailRendererApiModule } from './email-renderer';
import { EvaluationsApiModule } from './evaluations';
import { EventsApiModule } from './events';
import { FeatureFlagsApiModule } from './feature-flags';
import { FilesApiModule } from './files';
import { FoldersApiModule } from './folders';
import { FormFieldsApiModule } from './form-fields';
import { FormsApiModule } from './forms';
import { ImportExportApiModule } from './import-export';
import { JourneysApiModule } from './journeys';
import { LabelsApiModule } from './labels';
import { MajorsApiModule } from './majors';
import { MappingsApiModule } from './mappings';
import { MediaApiModule } from './media';
import { NetworkRolesApiModule } from './network-roles';
import { OrganizationsApiModule } from './organizations';
import { OverviewApiModule } from './overview';
import { PacksApiModule } from './packs';
import { Page451ApiModule } from './page451';
import { PaymentProvidersApiModule } from './payment-providers';
import { PermissionsApiModule } from './permissions';
import { PhoneNumbersApiModule } from './phone-numbers';
import { PortalApiModule, PortalPublicApiModule } from './portal';
import { ProfileTemplatesApiModule } from './profile-templates';
import { PushNotificationsApiModule } from './push-notifications';
import { RelationsApiModule } from './relations';
import { RequestInformationsApiModule } from './request-informations';
import { SamlApiModule } from './saml';
import { SchoolsApiModule } from './schools';
import { SegmentsApiModule } from './segments';
import { SettingsApiModule } from './settings';
import { SFTPApiModule } from './sftp';
import { SitesApiModule } from './sites';
import { SourceCodesApiModule } from './source-codes';
import { StripeApiModule } from './stripe';
import { StudentAssistantsApiModule } from './student-assistants';
import { StudentTypesApiModule } from './student-types';
import { SupplementalFormsApiModule } from './supplemental-forms';
import { SurveysApiModule } from './surveys';
import { TagsApiModule } from './tags';
import { TasksApiModule } from './tasks';
import { TasksTemplatesApiModule } from './tasks-templates';
import { TaxonomiesApiModule } from './taxonomies';
import { TeamsApiModule } from './teams';
import { TemplatesApiModule } from './templates';
import { TermsApiModule } from './terms';
import { TerritoriesApiModule } from './territories';
import { TokensApiModule } from './tokens';
import { TwilioApiModule } from './twilio';
import { UnsplashApiModule } from './unsplash';
import { UserApiModule } from './user';
import { ValidationsApiModule } from './validations';
import { VisibilityGroupsApiModule } from './visibility-groups';
import { WebhooksApiModule } from './webhooks';
import { WebpushSubscriptionsApiModule } from './webpush-subscriptions';
import { WorkflowsApiModule } from './workflows';
import { ZoomApiModule } from './zoom';

/**
 * Kitchen sink for all element451 API modules
 */
@NgModule({
  imports: [
    // core
    AuthApiModule.forRoot(),
    Api451CoreModule.forRoot(),
    // common
    AdminsApiModule.forRoot(),
    AIApiModule.forRoot(),
    ApplicationsApiModule.forRoot(),
    ApplicationsMessagesApiModule.forRoot(),
    CampusesApiModule.forRoot(),
    CeebApiModule.forRoot(),
    ChecklistsApiModule.forRoot(),
    ConditionsApiModule.forRoot(),
    ConnectorsApiModule.forRoot(),
    ContentBlocksApiModule.forRoot(),
    CouponsApiModule.forRoot(),
    CovideoApiModule.forRoot(),
    DataApiModule.forRoot(),
    DataFieldsApiModule.forRoot(),
    DataSourcesApiModule.forRoot(),
    DocumentsApiModule.forRoot(),
    EvaluationsApiModule.forRoot(),
    FeatureFlagsApiModule.forRoot(),
    FilesApiModule.forRoot(),
    FoldersApiModule,
    FormFieldsApiModule.forRoot(),
    FormsApiModule.forRoot(),
    ImportExportApiModule.forRoot(),
    LabelsApiModule.forRoot(),
    MajorsApiModule.forRoot(),
    MappingsApiModule.forRoot(),
    DegreesApiModule.forRoot(),
    CoursesApiModule.forRoot(),
    TasksApiModule.forRoot(),
    TasksTemplatesApiModule.forRoot(),
    MediaApiModule.forRoot(),
    OverviewApiModule.forRoot(),
    PacksApiModule.forRoot(),
    PhoneNumbersApiModule.forRoot(),
    PermissionsApiModule.forRoot(),
    RelationsApiModule.forRoot(),
    SchoolsApiModule.forRoot(),
    SegmentsApiModule.forRoot(),
    SettingsApiModule.forRoot(),
    SitesApiModule.forRoot(),
    SourceCodesApiModule.forRoot(),
    StudentTypesApiModule.forRoot(),
    TagsApiModule,
    TaxonomiesApiModule.forRoot(),
    TemplatesApiModule.forRoot(),
    TermsApiModule.forRoot(),
    TerritoriesApiModule.forRoot(),
    TokensApiModule.forRoot(),
    TwilioApiModule.forRoot(),
    UserApiModule.forRoot(),
    DepositsApiModule.forRoot(),
    RequestInformationsApiModule.forRoot(),
    PaymentProvidersApiModule.forRoot(),
    ValidationsApiModule.forRoot(),
    ZoomApiModule.forRoot(),
    UnsplashApiModule.forRoot(),
    SurveysApiModule.forRoot(),
    Conversations451ApiModule.forRoot(),
    ConversationViewsApiModule.forRoot(),
    StripeApiModule.forRoot(),
    OrganizationsApiModule.forRoot(),
    JourneysApiModule.forRoot(),
    SupplementalFormsApiModule.forRoot(),
    AppointmentsApiModule.forRoot(),
    CopilotApiModule.forRoot(),
    ApiKeysApiModule.forRoot(),
    EmailRendererApiModule.forRoot(),
    NetworkRolesApiModule.forRoot(),
    PushNotificationsApiModule.forRoot(),
    WebpushSubscriptionsApiModule.forRoot(),
    // features
    Campaigns451ApiModule.forRoot(),
    Decision451ApiModule.forRoot(),
    DeduplicationApiModule.forRoot(),
    EventsApiModule.forRoot(),
    Page451ApiModule.forRoot(),
    WorkflowsApiModule.forRoot(),
    VisibilityGroupsApiModule.forRoot(),
    ProfileTemplatesApiModule.forRoot(),
    WebhooksApiModule.forRoot(),
    TeamsApiModule.forRoot(),
    DomainsApiModule.forRoot(),
    SamlApiModule.forRoot(),
    FormFieldsApiModule.forRoot(),
    StudentAssistantsApiModule.forRoot(),
    BoltSearchApiModule.forRoot(),
    PortalPublicApiModule.forRoot(),
    PortalApiModule.forRoot(),
    SFTPApiModule.forRoot(),
    ArticlesApiModule.forRoot()
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class Api451Module {
  static forRoot(
    providers: Provider[] = []
  ): ModuleWithProviders<Api451Module> {
    return {
      ngModule: Api451Module,
      providers: providers
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: Api451Module
  ) {
    throwIfAlreadyLoaded(parentModule, 'Api451Module');
  }
}
