import * as qs from 'querystring';
import {
  auto,
  cardinal,
  fit,
  flip,
  format,
  fullCardinal,
  ImgixParams,
  ImgixRect,
  mask,
  orient,
  palette
} from './types';

interface ImgixOptions {
  parseQueryParams?: boolean;
}

export class Imgix {
  protected url?: string;
  protected readonly defaultOptions: ImgixOptions = { parseQueryParams: false };

  params: ImgixParams;

  constructor(
    url?: string,
    options: ImgixOptions = {},
    params: ImgixParams = {}
  ) {
    this.params = params;
    options = { ...this.defaultOptions, ...options };
    if (url) this.setUrl(url, options, params);
  }

  setUrl(
    url: string,
    options: ImgixOptions = this.defaultOptions,
    params: ImgixParams = {}
  ) {
    const urlObj = new URL(url);
    // always use https for imgix
    urlObj.protocol = 'https:';

    // cleanup all the existing query params
    this.url = `${urlObj.origin}${urlObj.pathname}`;

    if (urlObj.search && options.parseQueryParams) {
      const parsedQueryParams = qs.parse(urlObj.search.substr(1));
      this.params = { ...parsedQueryParams, ...params };
    }
  }

  getUrl() {
    return this.url;
  }

  getParam<T extends keyof ImgixParams>(param: T): ImgixParams[T] {
    return this.params[param];
  }

  /**
   * @method brightness
   * @param {number} x
   * @returns {Imgix}
   */

  brightness(x: number): Imgix {
    const valid = this.between(-100, 100);

    if (valid(x)) {
      this.params.bri = x;
      return this;
    }

    throw Error(`Brightness value must be a number between -100 and 100.`);
  }

  /**
   * @method contrast
   * @param {number} x
   * @returns {Imgix}
   */

  contrast(x: number): Imgix {
    const valid = this.between(-100, 100);

    if (valid(x)) {
      this.params.con = x;
      return this;
    }

    throw Error(`Contrast value must be a number between -100 and 100.`);
  }

  /**
   * @method exposure
   * @param {number} x
   * @returns {Imgix}
   */

  exposure(x: number): Imgix {
    const valid = this.between(-100, 100);

    if (valid(x)) {
      this.params.exp = x;
      return this;
    }

    throw Error(`Exposure value must be a number between -100 and 100.`);
  }

  /**
   * @method gamma
   * @param {number} x
   * @returns {Imgix}
   */

  gamma(x: number): Imgix {
    const valid = this.between(-100, 100);

    if (valid(x)) {
      this.params.gam = x;
      return this;
    }

    throw Error(`Gamma value must be a number between -100 and 100.`);
  }

  /**
   * @method highlight
   * @param {number} x
   * @returns {Imgix}
   */

  highlight(x: number): Imgix {
    const valid = this.between(-100, 0);

    if (valid(x)) {
      this.params.high = x;
      return this;
    }

    throw Error(`Highlight value must be a number between -100 and 0.`);
  }

  /**
   * @method hueShift
   * @param {number} x
   * @returns {Imgix}
   */

  hueShift(x: number): Imgix {
    const valid = this.between(0, 359);

    if (valid(x)) {
      this.params.hue = x;
      return this;
    }

    throw Error(`Hue Shift value must be a number between 0 and 359.`);
  }

  /**
   * @method invert
   * @param {boolean} x
   * @returns {Imgix}
   */

  invert(x: boolean): Imgix {
    if (x) this.params.invert = x;
    return this;
  }

  /**
   * @method saturation
   * @param {number} x
   * @returns {Imgix}
   */

  saturation(x: number): Imgix {
    const valid = this.between(-100, 100);

    if (valid(x)) {
      this.params.sat = x;
      return this;
    }

    throw Error(`Saturation value must be a number between -100 and 100.`);
  }

  /**
   * @method shadow
   * @param {number} x
   * @returns {Imgix}
   */

  shadow(x: number): Imgix {
    const valid = this.between(0, 100);

    if (valid(x)) {
      this.params.shad = x;
      return this;
    }

    throw Error(`Shadow value must be a number between 0 and 100.`);
  }

  /**
   * @method sharpen
   * @param {number} x
   * @returns {Imgix}
   */

  sharpen(x: number): Imgix {
    const valid = this.between(0, 100);

    if (valid(x)) {
      this.params.sharp = x;
      return this;
    }

    throw Error(`Sharpen value must be a number between 0 and 100.`);
  }

  /**
   * @method unsharpMask
   * @param {number} x
   * @returns {Imgix}
   */

  unsharpMask(x: number): Imgix {
    const valid = this.between(-100, 100);

    if (valid(x)) {
      this.params.usm = x;
      return this;
    }

    throw Error(`Unsharp Mask value must be a number between -100 and 100.`);
  }

  /**
   * @method unsharpMaskRadius
   * @param {number} x
   * @returns {Imgix}
   */

  unsharpMaskRadius(x: number): Imgix {
    const valid = this.between(-100, 100);

    if (valid(x)) {
      this.params.usmrad = x;
      return this;
    }

    throw Error(
      `Unsharp Mask Radius value must be a number between -100 and 100.`
    );
  }

  /**
   * @method vibrance
   * @param {number} x
   * @returns {Imgix}
   */

  vibrance(x: number): Imgix {
    const valid = this.between(-100, 100);

    if (valid(x)) {
      this.params.vib = x;
      return this;
    }

    throw Error(`Vibrance value must be a number between -100 and 100.`);
  }

  /**
   * @method mask
   * @param {mask} x
   * @returns {Imgix}
   */

  mask(x: mask): Imgix {
    this.params.mask = x;
    return this;
  }

  /**
   * @method cornerRadius
   * @param {cornerRadius} x
   * @returns {Imgix}
   */

  cornerRadius(x: number): Imgix {
    this.params['corner-radius'] = x;
    return this;
  }

  /**
   * ===========================================================================
   * BLENDING
   * ===========================================================================
   */

  /**
   * @method blend
   * @param {string} x
   * @returns {Imgix}
   */

  blend(x: string): Imgix {
    this.params.blend = x;
    return this;
  }

  /**
   * @method blendAlign
   * @param {[fullCardinal]} x
   * @returns {Imgix}
   */

  blendAlign(x: [fullCardinal]): Imgix {
    this.params.blendAlign = x;
    return this;
  }

  /**
   * @method blendAlpha
   * @param {number} x
   * @returns {Imgix}
   */

  blendAlpha(x: number): Imgix {
    const valid = this.between(0, 100);

    if (valid(x)) {
      this.params.blendAlpha = x;
      return this;
    }

    throw Error(`Blend Alpha value must be a number between 0 and 100.`);
  }

  /**
   * @method blendCrop
   * @param {[cardinal | 'faces']}
   * @returns {Imgix}
   */

  blendCrop(x: [cardinal | 'faces']): Imgix {
    this.params.blendCrop = x;
    return this;
  }

  /**
   * @method crop
   * @param {[cardinal | 'faces']}
   * @returns {Imgix}
   */

  crop(x: [cardinal | 'faces']): Imgix {
    this.params.crop = x;
    return this;
  }

  /**
   * @method aspectRatio
   * @param {string} x
   * @returns {Imgix}
   */

  aspectRatio(x: string): Imgix {
    this.params.ar = x;
    return this;
  }

  getAspectRatio(): string | undefined {
    return this.params.ar;
  }

  /**
   * ===========================================================================
   * SIZE
   * ===========================================================================
   */

  /**
   * @method fit
   * @param {fit} x
   * @returns {Imgix}
   */

  fit(x: fit): Imgix {
    this.params.fit = x;
    return this;
  }

  /**
   * @method facepad
   * @param {number} x
   * @returns {Imgix}
   */

  facepad(x: number): Imgix {
    this.params.facepad = x;
    return this;
  }

  /**
   * @method width
   * @param {number} x
   * @returns {Imgix}
   */

  width(x: number): Imgix {
    this.params.w = x;
    return this;
  }

  getWidth(): number | null {
    return this.params.w ? Number(this.params.w) : null;
  }

  /**
   * @method height
   * @param {number} x
   * @returns {Imgix}
   */

  height(x: number): Imgix {
    this.params.h = x;
    return this;
  }

  getHeight(): number | null {
    return this.params.h ? Number(this.params.h) : null;
  }

  /**
   * @param {number} x
   * @returns {Imgix}
   */
  maxWidth(x: number): Imgix {
    this.params['max-w'] = x;
    return this;
  }

  /**
   * @param {number} x
   * @returns {Imgix}
   */
  maxHeight(x: number): Imgix {
    this.params['max-h'] = x;
    return this;
  }

  /**
   * @param {number} x
   * @returns {Imgix}
   */
  minWidth(x: number): Imgix {
    this.params['min-w'] = x;
    return this;
  }

  /**
   * @param {number} x
   * @returns {Imgix}
   */
  minHeight(x: number): Imgix {
    this.params['min-h'] = x;
    return this;
  }

  /**
   * @method format
   * @param {format} x
   * @returns {Imgix}
   */

  format(x: format): Imgix {
    this.params.fm = x;
    return this;
  }

  /**
   * @method quality
   * @param {number} x
   * @returns {Imgix}
   */

  quality(x: number): Imgix {
    const valid = this.between(0, 100);

    if (valid(x)) {
      this.params.q = x;
      return this;
    }

    throw Error(`Quality value must be a number between -100 and 100.`);
  }

  /**
   * @method rect
   * @param {ImgixRect} x
   * @returns {Imgix}
   */

  rect(rect: ImgixRect): Imgix {
    this.params.rect = rect && `${rect.x},${rect.y},${rect.w},${rect.h}`;
    return this;
  }

  getRect(): ImgixRect | undefined {
    const rect = this.params.rect?.split(',');
    return (
      rect && {
        x: parseInt(rect[0], 10),
        y: parseInt(rect[1], 10),
        w: parseInt(rect[2], 10),
        h: parseInt(rect[3], 10)
      }
    );
  }

  /**
   * ===========================================================================
   * ROTATION
   * ===========================================================================
   */

  /**
   * @method flip
   * @param {flip} x
   * @returns {Imgix}
   */

  flip(x: flip): Imgix {
    this.params.flip = x;
    return this;
  }

  /**
   * @method orient
   * @param {orient} x
   * @return {Imgix}
   */

  orient(x: orient): Imgix {
    this.params.orient = x;
    return this;
  }

  rotation(x: number): Imgix {
    const valid = this.between(0, 359);

    if (valid(x)) {
      this.params.rot = x;
      return this;
    }

    throw Error(`Rotation value must be a number between 0 and 359.`);
  }

  /**
   * ===========================================================================
   * COLOR PALETTE
   * ===========================================================================
   */

  palette(x: palette): Imgix {
    this.params.palette = x;
    return this;
  }

  colors(x: number): Imgix {
    const valid = this.between(0, 16);

    if (valid(x)) {
      this.params.colors = x;
      return this;
    }

    throw Error('Colors value must be a number between 0 and 16.');
  }

  prefix(x: string | number): Imgix {
    this.params.prefix = x;
    return this;
  }

  /**
   * ===========================================================================
   * AUTO
   * ===========================================================================
   */

  auto(x: auto | auto[]): Imgix {
    if (Array.isArray(x)) {
      this.params.auto = x.join(',');
    } else {
      this.params.auto = x;
    }

    return this;
  }

  /**
   * ===========================================================================
   * UTILS
   * ===========================================================================
   */

  toString(): string {
    const query = qs.stringify(this.params as Record<string, any>);
    return `${this.url}?${query}`;
  }

  clone(url?: string) {
    url = url || this.url;
    const cloned = new Imgix(url);
    cloned.params = this.params;
    return cloned;
  }

  private between(x: number, y: number): (z: number) => boolean {
    return (z: number) => z >= x && z <= y;
  }
}
