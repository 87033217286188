import {
  CountOrganizationsQuery,
  CountOrganizationsQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const countOrganizationsQuery = gql<
  CountOrganizationsQuery,
  CountOrganizationsQueryVariables
>`
  query CountOrganizations($q: String!) {
    countOrganizations(input: { q: $q })
  }
`;
