import { Injectable } from '@angular/core';
import { mapToPayload } from '@element451-libs/utils451/rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { merge, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import * as fromDashboard from '../dashboard/dashboard.actions';
import { DASHBOARD_ACTIONS } from '../dashboard/dashboard.actions';
import * as actions from './generate-preview.actions';
import { GENERATE_PREVIEW_ACTIONS } from './generate-preview.actions';

@Injectable()
export class GeneratePreviewService {
  constructor(
    private store: Store,
    private actions$: Actions
  ) {}

  generate() {
    const uid = uuid();

    this.store.dispatch(new actions.GeneratePreviewAction({ uid }));

    const onlyThisUid = <T extends actions.PreviewGenerationAction>(
      src$: Observable<T>
    ) => src$.pipe(filter(action => action.payload.uid === uid));

    const success$ = this.actions$.pipe(
      ofType<actions.PreviewGeneratedAction>(
        GENERATE_PREVIEW_ACTIONS.PREVIEW_GENERATED
      ),
      onlyThisUid,
      mapToPayload,
      map(payload => payload.preview)
    );

    const fail$ = this.actions$.pipe(
      ofType<actions.PreviewGenerationFailAction>(
        GENERATE_PREVIEW_ACTIONS.PREVIEW_GENERATION_FAIL
      ),
      onlyThisUid,
      mapToPayload,
      map(payload => payload.error)
    );

    const cancel$ = this.actions$.pipe(
      ofType<fromDashboard.SwitchApplicationAction>(
        DASHBOARD_ACTIONS.SWITCH_APPLICATION
      ),
      map(_ => ({ status: 'revert' as const }))
    );

    return merge(success$, fail$, cancel$).pipe(take(1));
  }
}
