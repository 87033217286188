import {
  CountUsersByTextQuery,
  CountUsersByTextQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const countUsersByTextQuery = gql<
  CountUsersByTextQuery,
  CountUsersByTextQueryVariables
>`
  query CountUsersByText($text: String!) {
    countUsers(input: { query: $text })
  }
`;
