import { startsWith } from 'lodash';
import { Tokens } from 'marked';
import { MarkedOptions, MarkedRenderer } from 'ngx-markdown';

const ANCHOR_FUNC = ['mailto:', 'tel:', '#'];

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  renderer.link = ({ href, title, text }: Tokens.Link) => {
    const titleAttr = title ? `title=${title}` : '';
    const hrefAttr = href ? `href=${href}` : '';
    const targetAttr = ANCHOR_FUNC.some(func => startsWith(href, func))
      ? ''
      : 'target="_blank"';
    return `<a ${hrefAttr} ${titleAttr} ${targetAttr}>${text}</a>`;
  };

  return {
    renderer,
    gfm: true,
    breaks: true
  };
}
