import { Injectable } from '@angular/core';
import {
  ApplicationsApi,
  FormsApi,
  responseData,
  UserApiService
} from '@element451-libs/api451';
import { cached, mapToPayload } from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Dictionary } from 'lodash';
import { of } from 'rxjs';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { Forms } from '../forms';
import * as fromEditProfile from './edit-profile.actions';
import { EDIT_PROFILE_ACTIONS } from './edit-profile.actions';
import { EditProfileService } from './edit-profile.service';

@Injectable()
export class EditProfileEffects {
  constructor(
    private actions$: Actions<fromEditProfile.EditProfileAction>,
    private userApiService: UserApiService,
    private editProfileService: EditProfileService,
    private store: Store<any>,
    private forms: Forms
  ) {}

  getProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EDIT_PROFILE_ACTIONS.GET_PROFILE_REQUEST),
      concatMap(_ =>
        this.userApiService.getBasicInformation().pipe(
          responseData,
          map(data => new fromEditProfile.GetProfileSuccessAction(data)),
          catchError(err => of(new fromEditProfile.GetProfileFailAction(err)))
        )
      )
    )
  );

  updateProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EDIT_PROFILE_ACTIONS.UPDATE_PROFILE_REQUEST),
      mapToPayload,
      concatMap(payload =>
        this.userApiService.updateBasicInformation(payload).pipe(
          responseData,
          withLatestFrom(this.forms.entities$, this.editProfileService.forms$),
          map(mapToUpdateProfileSuccessResponse),
          map(data => new fromEditProfile.UpdateProfileSuccessAction(data)),
          catchError(err =>
            of(new fromEditProfile.UpdateProfileFailAction(err))
          )
        )
      )
    )
  );

  getForms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EDIT_PROFILE_ACTIONS.GET_FORMS_REQUEST),
      cached(
        this.editProfileService.forms$.pipe(map(forms => !!forms)),
        this.store
      ),
      concatMap(_ =>
        this.userApiService.getBasicInformationForm().pipe(
          responseData,
          map(data => new fromEditProfile.GetFormsSuccessAction(data)),
          catchError(err => of(new fromEditProfile.GetFormsFailAction(err)))
        )
      )
    )
  );
}

function mapToUpdateProfileSuccessResponse([data, formEntities, formGuids]: [
  ApplicationsApi.FormEntry[],
  Dictionary<ApplicationsApi.Form>,
  string[]
]) {
  const fields = formGuids.reduce((acc, curr) => {
    acc = [...acc, ...formEntities[curr].fields];
    return acc;
  }, [] as FormsApi.Field[]);

  return { fields, data };
}
