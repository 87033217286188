import { Action, Creator } from '@ngrx/store';

export const CACHE_NAMESPACE = '[CACHE]';

export enum CACHE_ACTIONS {
  MISS = '[CACHE] Miss',
  HIT = '[CACHE] Hit'
}

export abstract class CacheableAction<T = any> implements Action {
  type!: string;

  payload!: T;

  meta?: any;

  private clearCache = false;

  /**
   * (DO NOT USE) Used only by cached operator
   */
  get _clearCache(): boolean {
    return this.clearCache;
  }

  refreshCache(refresh: boolean) {
    this.clearCache = refresh;
    return this;
  }
}

const clearCacheKey = '_clearCache';
/**
 * Helpers for composing cacheable action functionaliy with action creators
 *
 * Usage when declaring:
 * ```ts
 * const action = cacheable(createAction('some action type'));
 * ```
 *
 * When clearing the cache:
 *
 * ```ts
 * clearCache() {
 *  this.store.dispatch(refreshCache(action));
 * }
 * ```
 *
 */
export function cacheable<AC extends Creator>(action: AC): AC {
  action[clearCacheKey] = false;
  return action;
}
export function refreshCache<A extends Action>(action: A): A {
  if (clearCacheKey in action) {
    action[clearCacheKey] = true;
  }
  return action;
}

export class CacheMissAction implements Action {
  readonly type = CACHE_ACTIONS.MISS;

  constructor(public payload: { type: string; payload: any }) {}
}

export class CacheHitAction implements Action {
  readonly type = CACHE_ACTIONS.HIT;

  constructor(public payload: { type: string; payload: any }) {}
}

export type CacheAction = CacheMissAction | CacheHitAction;
