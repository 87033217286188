import {
  GetOrganizationQuery,
  GetOrganizationQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const getOrganizationQuery = gql<
  GetOrganizationQuery,
  GetOrganizationQueryVariables
>`
  query GetOrganization($id: String!) {
    getOrganization(input: { id: $id }) {
      _id
      guid
      name
      type
      owner
      website
      domain
      territory
      identities
      phones {
        number
        name
        default
        country_code
        country_alpha_2
      }
      addresses {
        type
        country
        street_1
        street_2
        city
        state
        zipcode
        default
      }
      labels
      created_at
      updated_at
    }
  }
`;
