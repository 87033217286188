import { Injectable } from '@angular/core';
import {
  ApplicationsApi,
  ApplicationsApiService,
  responseData
} from '@element451-libs/api451';
import { cached, mapToPayload } from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { reduce } from 'lodash';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  map,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import * as fromUserApplications from '../user-applications/user-applications.actions';
import { USER_APPLICATIONS_ACTIONS } from '../user-applications/user-applications.actions';
import * as fromUserData from './user-data.actions';
import { USER_DATA_ACTIONS } from './user-data.actions';
import { UserData } from './user-data.service';

@Injectable()
export class UserDataEffects {
  constructor(
    private actions$: Actions<
      fromUserData.UserDataActions | fromUserApplications.UserApplicationsAction
    >,
    private applicationsApiService: ApplicationsApiService,
    private userData: UserData,
    private store: Store<any>
  ) {}

  selectRegistration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_APPLICATIONS_ACTIONS.SELECT_REGISTRATION),
      mapToPayload,
      map(
        registrationId =>
          new fromUserData.LoadUserDataRequestAction(registrationId)
      )
    )
  );

  loadUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_DATA_ACTIONS.LOAD_USER_DATA_REQUEST),
      switchMap(action =>
        of(action).pipe(
          cached(
            this.userData.registrationId$.pipe(
              map(id => id === action.payload)
            ),
            this.store
          )
        )
      ),
      mapToPayload,
      concatMap(registrationId =>
        this.applicationsApiService.getUserApplicationData(registrationId).pipe(
          responseData,
          map(normalizeUserApplicationData),
          map(
            data =>
              new fromUserData.LoadUserDataSuccessAction({
                registrationId,
                data
              })
          ),
          catchError(err => of(new fromUserData.LoadUserDataFailAction(err)))
        )
      )
    )
  );

  loadFieldSlugNameMappings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_DATA_ACTIONS.LOAD_FIELD_SLUG_NAME_MAPPINGS_REQUEST),
      switchMap(action =>
        of(action).pipe(
          cached(
            this.userData.applicationGuid$.pipe(
              withLatestFrom(this.userData.fieldSlugNameMappings$),
              map(([guid, mappings]) => guid === action.payload && !!mappings)
            ),
            this.store
          )
        )
      ),
      mapToPayload,
      concatMap(applicationGuid =>
        this.applicationsApiService
          .getFieldSlugNameMappings(applicationGuid)
          .pipe(
            responseData,
            map(
              data =>
                new fromUserData.LoadFieldSlugNameMappingsSuccessAction(data)
            ),
            catchError(err =>
              of(new fromUserData.LoadFieldSlugNameMappingsFailAction(err))
            )
          )
      )
    )
  );
}

function normalizeUserApplicationData(
  userData: ApplicationsApi.UserApplicationData
) {
  return reduce(
    userData,
    (acc, value, key) => {
      // we ignore null values since they provide no information
      // but can override local state during prepopulation
      if (value !== null) acc[key] = value;
      return acc;
    },
    {}
  );
}
