import { Injectable } from '@angular/core';
import { ApplicationsApiService } from '@element451-libs/api451';
import { PusherChannel, PusherService } from '@element451-libs/pusher';
import { mapToPayload } from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { merge, Observable, Observer, of } from 'rxjs';
import {
  catchError,
  ignoreElements,
  map,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import { AccountService } from '../account';
import { UserApplications } from '../user-applications';
import { UserData } from '../user-data';
import * as actions from './generate-preview.actions';
import {
  ApplicationPreviewEvent,
  GENERATE_PREVIEW_ACTIONS
} from './generate-preview.actions';

const previewEvent = 'luminous';

export const enum PreviewEvent {
  CREATED = 'application_preview_created'
}

@Injectable()
export class GeneratePreviewEffects {
  constructor(
    private pusher: PusherService,
    private userApplications: UserApplications,
    private account: AccountService,
    private applicationsApi: ApplicationsApiService,
    private userData: UserData,
    private actions$: Actions<actions.GeneratePreviewAction>
  ) {}

  generatePreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GENERATE_PREVIEW_ACTIONS.GENERATE_PREVIEW),
      mapToPayload,
      map(payload => payload.uid),
      withLatestFrom(
        this.userApplications.selectedApplicationGuid$,
        this.userData.registrationId$,
        this.account.userId$
      ),
      switchMap(([uid, appGuid, registrationId, userId]) => {
        // We need to first open up the channel before pinging the api
        const channel = this.openChannel(userId);
        const { subscription$, event$ } = listenChannelEvents(channel, uid);

        const ping$ = subscription$.pipe(
          switchMap(_ =>
            this.applicationsApi.generatePreview(appGuid, registrationId)
          ),
          ignoreElements()
        );

        return merge(event$, ping$).pipe(
          catchError(error =>
            of(new actions.PreviewGenerationFailAction({ uid, error }))
          )
        );
      })
    )
  );

  private openChannel(userId: string) {
    return this.pusher.listenChannel(userId);
  }
}

const listenChannelEvents = (channel: PusherChannel, uid: string) => {
  const subscription$ = new Observable((observer: Observer<boolean>) => {
    channel.listenSubscription((success: boolean) => {
      observer.next(success);
      observer.complete();
    });

    const unsubscribe = () => channel.disconnectSubscription();

    return { unsubscribe };
  });

  const event$ = new Observable(
    (observer: Observer<actions.PreviewGenerationAction>) => {
      channel.listenEvent(previewEvent, (event: ApplicationPreviewEvent) => {
        const { data, issue } = event;

        switch (issue) {
          case PreviewEvent.CREATED: {
            observer.next(
              new actions.PreviewGeneratedAction({ uid, preview: data })
            );
            break;
          }
          default: {
            observer.error(
              new actions.PreviewGenerationFailAction({
                uid,
                error: { status: 'failed' }
              })
            );
            break;
          }
        }

        observer.complete();
      });

      const unsubscribe = () => channel.disconnect();

      return { unsubscribe };
    }
  );

  return { subscription$, event$ };
};
