import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationsApiService } from '@element451-libs/api451';
import { ElmDialogService } from '@element451-libs/components451/dialog';
import { PaymentApi, PaymentProvidersApi } from '@element451-libs/models451';
import { falsey, truthy } from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  exhaustMap,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import {
  ApplicationPaymentConfirmationDialogComponent,
  ApplicationPaymentDialogComponent,
  DefaultPaymentIntegrationResponse,
  DepositPaymentConfirmationDialogComponent,
  DepositPaymentDialogComponent,
  PaymentDialogResponse,
  SubmitConfirmationDialogComponent
} from '../../components';
import { ApplicationStateService } from '../application-state';
import { DashboardService } from '../dashboard';
import { UserApplications } from '../user-applications';
import { UserData } from '../user-data';
import * as fromSubmit from './submit-application.actions';
import { SUBMIT_ACTIONS } from './submit-application.actions';
import { SubmitFormService } from './submit-form.service';

const calculateState =
  (applicationState: ApplicationStateService) =>
  <T>(src$: Observable<T>) =>
    src$.pipe(
      withLatestFrom(applicationState.flags$),
      map(([_, flags]) => ({ ...flags }))
    );

@Injectable()
export class SubmitApplicationEffects {
  constructor(
    private actions$: Actions<fromSubmit.SubmitActions>,
    private applicationsApi: ApplicationsApiService,
    private userApplications: UserApplications,
    private dashboard: DashboardService,
    private userData: UserData,
    private submitForm: SubmitFormService,
    private dialog: ElmDialogService,
    private applicationState: ApplicationStateService
  ) {}

  onSubmit$ = this.actions$.pipe(
    ofType(SUBMIT_ACTIONS.START_SUBMIT_PROCESS),
    calculateState(this.applicationState)
  );

  onPayment$ = this.actions$.pipe(
    ofType(SUBMIT_ACTIONS.START_PAYMENT_PROCESS),
    calculateState(this.applicationState)
  );

  paymentBeforeSubmission$ = createEffect(
    () =>
      this.onSubmit$.pipe(
        filter(({ payBeforeSubmission }) => payBeforeSubmission),
        exhaustMap(_ => this.openAppPaymentDialog({ withConfirmation: false })),
        debounceTime(0),
        exhaustMap(_ =>
          this.openSubmitApplicationDialog({ withConfirmation: true })
        )
      ),
    { dispatch: false }
  );

  paymentAfterSubmission$ = createEffect(
    () =>
      this.onSubmit$.pipe(
        filter(({ payAfterSubmission }) => payAfterSubmission),
        exhaustMap(_ =>
          this.openSubmitApplicationDialog({ withConfirmation: false })
        ),
        exhaustMap(_ => this.openAppPaymentDialog({ withConfirmation: true }))
      ),
    { dispatch: false }
  );

  onlySubmitWithoutPayment$ = createEffect(
    () =>
      this.onSubmit$.pipe(
        filter(({ onlySubmitWithoutPayment }) => onlySubmitWithoutPayment),
        exhaustMap(_ =>
          this.openSubmitApplicationDialog({ withConfirmation: true })
        )
      ),
    { dispatch: false }
  );

  openPayment$ = createEffect(
    () =>
      this.onPayment$.pipe(
        filter(({ shouldPay }) => shouldPay),
        exhaustMap(_ => this.openAppPaymentDialog({ withConfirmation: true }))
      ),
    { dispatch: false }
  );

  openDepositPayment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SUBMIT_ACTIONS.PAY_DEPOSIT),
        exhaustMap(_ => this.openDepositPaymentDialog())
      ),
    { dispatch: false }
  );

  openSubmitApplicationDialog(configuration: { withConfirmation: boolean }) {
    const { dialogRef, response$ } = this.submitForm.open();

    const openConfirmation = <T>(src$: Observable<T>): Observable<boolean> =>
      src$.pipe(
        configuration.withConfirmation
          ? switchMap(_ =>
              this.openSubmitConfirmationDialog().pipe(map(() => true))
            )
          : map(() => true)
      );

    const submitFormToApi = <T>(src$: Observable<T>) =>
      src$.pipe(
        tap(_ => (dialogRef.componentInstance.loading = true)),
        withLatestFrom(
          this.userApplications.selectedApplicationGuid$,
          this.userData.registrationId$
        ),
        switchMap(([formData, applicationGuid, registrationId]) =>
          this.applicationsApi
            .submitApplication(applicationGuid, registrationId, formData)
            .pipe(
              map(_ => true),
              catchError((errorResponse: HttpErrorResponse) => {
                dialogRef.componentInstance.error =
                  errorResponse.error.data?.msg ||
                  errorResponse.error.userMessage;
                return of(false);
              })
            )
        ),
        tap(_ => (dialogRef.componentInstance.loading = false))
      );

    return response$.pipe(
      submitFormToApi,
      truthy,
      tap(_ => dialogRef.close()),
      tap(_ => this.dashboard.applicationSubmitted()),
      openConfirmation,
      take(1)
    );
  }

  openAppPaymentDialog(configuration: { withConfirmation: boolean }) {
    return this.withPayment$().pipe(
      switchMap(paymentSetup => {
        const dialogRef = this.dialog.openRef(
          ApplicationPaymentDialogComponent,
          {
            data: paymentSetup
          }
        );

        const closeDialog = (src$: Observable<any>): Observable<boolean> =>
          src$.pipe(
            tap(() => dialogRef.close()),
            map(() => true)
          );

        const cancel$ = dialogRef.afterClosed().pipe(falsey, closeDialog);

        const submit$ = dialogRef.componentInstance.onSubmit
          .asObservable()
          .pipe(
            truthy,
            /** trigger loading on the dialog */
            tap(_ => (dialogRef.componentInstance.loading = true))
          );

        const handlePaymentError = <T>(
          src$: Observable<boolean>
        ): Observable<boolean> =>
          src$.pipe(
            catchError((errorResponse: HttpErrorResponse) => {
              const message = getPaymentErrorMessage(errorResponse);
              dialogRef.componentInstance.loading = false;
              dialogRef.componentInstance.error = message;
              return of(false);
            })
          );

        const openConfirmation =
          (method: PaymentApi.PaymentMethod) =>
          <T>(src$: Observable<boolean>): Observable<boolean> =>
            src$.pipe(
              configuration.withConfirmation
                ? switchMap(() =>
                    this.openApplicationPaymentConfirmationDialog(method).pipe(
                      map(_ => true)
                    )
                  )
                : map(() => true)
            );

        const pay$ = submit$.pipe(
          switchMap(paymentResponse =>
            this.payApplication(paymentResponse).pipe(
              closeDialog,
              tap(() => this.dashboard.applicationPaid()),
              openConfirmation(paymentResponse.type),
              handlePaymentError
            )
          )
        );

        return pay$.pipe(takeUntil(cancel$), truthy, take(1));
      })
    );
  }

  openDepositPaymentDialog() {
    return this.withDeposit$().pipe(
      switchMap(depositSetup => {
        const dialogRef = this.dialog.openRef(DepositPaymentDialogComponent, {
          data: depositSetup
        });

        const closeDialog = (src$: Observable<any>): Observable<boolean> =>
          src$.pipe(
            tap(() => dialogRef.close()),
            map(() => true)
          );

        const cancel$ = dialogRef.afterClosed().pipe(falsey, closeDialog);

        const submit$ = dialogRef.componentInstance.onSubmit
          .asObservable()
          .pipe(
            truthy,
            /** trigger loading on the dialog */
            tap(_ => (dialogRef.componentInstance.loading = true))
          );

        const handlePaymentError = <T>(
          src$: Observable<boolean>
        ): Observable<boolean> =>
          src$.pipe(
            catchError((errorResponse: HttpErrorResponse) => {
              const message = getPaymentErrorMessage(errorResponse);
              dialogRef.componentInstance.loading = false;
              dialogRef.componentInstance.error = message;
              return of(false);
            })
          );

        const pay$ = submit$.pipe(
          switchMap(paymentResponse =>
            this.payDeposit(paymentResponse).pipe(
              closeDialog,
              tap(() => this.dashboard.depositPaid()),
              switchMap(_ =>
                this.openDepositPaymentConfirmationDialog().pipe(map(_ => true))
              ),
              handlePaymentError
            )
          )
        );

        return pay$.pipe(takeUntil(cancel$), truthy, take(1));
      })
    );
  }

  openApplicationPaymentConfirmationDialog(method: PaymentApi.PaymentMethod) {
    return this.dialog.open(ApplicationPaymentConfirmationDialogComponent, {
      data: method
    });
  }

  openDepositPaymentConfirmationDialog() {
    return this.dialog.open(DepositPaymentConfirmationDialogComponent);
  }

  openSubmitConfirmationDialog() {
    return this.dialog.open(SubmitConfirmationDialogComponent);
  }

  private payApplication(paymentResponse: PaymentDialogResponse) {
    const conditionId = paymentResponse.condition_id || null;
    const couponCode = paymentResponse.coupon_code || null;

    switch (paymentResponse.type) {
      case PaymentApi.PaymentMethod.Check:
        return this.payCheck(paymentResponse.payment, conditionId, couponCode);

      case PaymentApi.PaymentMethod.CreditCard: {
        if (paymentResponse.paid) {
          return of({ done: true });
        }

        return this.withPayment$().pipe(
          switchMap(payment => {
            switch (payment.cc_integration?.driver) {
              case PaymentProvidersApi.PaymentDriver.PaypalSb:
              case PaymentProvidersApi.PaymentDriver.StripeConnect:
              case PaymentProvidersApi.PaymentDriver.StripeOwned:
              case PaymentProvidersApi.PaymentDriver.FlyWire:
              case PaymentProvidersApi.PaymentDriver.FlyWireEmbed:
              case PaymentProvidersApi.PaymentDriver.TouchNetTLink:
                return of({ done: true });
            }
            return this.payCreditCard(
              paymentResponse.payment as DefaultPaymentIntegrationResponse[],
              conditionId,
              couponCode
            );
          })
        );
      }
    }
  }

  private payDeposit(paymentResponse: PaymentDialogResponse) {
    const conditionId = paymentResponse?.condition_id || null;
    const couponCode = paymentResponse?.coupon_code || null;

    switch (paymentResponse.type) {
      case PaymentApi.PaymentMethod.CreditCard: {
        if (paymentResponse.paid) {
          return of({ done: true });
        }
        return this.withDeposit$().pipe(
          switchMap(deposit => {
            switch (deposit.cc_integration?.driver) {
              case PaymentProvidersApi.PaymentDriver.PaypalSb:
              case PaymentProvidersApi.PaymentDriver.StripeConnect:
              case PaymentProvidersApi.PaymentDriver.StripeOwned:
              case PaymentProvidersApi.PaymentDriver.FlyWire:
              case PaymentProvidersApi.PaymentDriver.FlyWireEmbed:
              case PaymentProvidersApi.PaymentDriver.TouchNetTLink:
                return of({ done: true });
            }
            return this.payDepositCreditCard(
              paymentResponse.payment as DefaultPaymentIntegrationResponse[],
              conditionId,
              couponCode
            );
          })
        );
      }
    }
  }

  private payCheck(
    checkNumber: string,
    conditionId: string | null,
    couponCode: string | null
  ) {
    return this.withApplicationParams$().pipe(
      switchMap(params =>
        this.applicationsApi.sendCheckPayment(
          params.applicationGuid,
          params.registrationId,
          checkNumber,
          conditionId,
          couponCode
        )
      )
    );
  }

  private payCreditCard(
    creditCardData: { name: string; value: string }[],
    conditionId: string | null,
    couponCode: string | null
  ) {
    return this.withApplicationParams$().pipe(
      switchMap(params =>
        this.applicationsApi.sendCreditCardPayment(
          params.applicationGuid,
          params.registrationId,
          creditCardData,
          conditionId,
          couponCode
        )
      )
    );
  }

  private payDepositCreditCard(
    creditCardData: { name: string; value: string }[],
    conditionId: string,
    couponCode: string | null
  ) {
    return this.withDepositParams$().pipe(
      switchMap(params =>
        this.applicationsApi.sendDepositCreditCardPayment(
          params.depositId,
          params.context,
          creditCardData,
          conditionId,
          couponCode
        )
      )
    );
  }

  private withPayment$() {
    return this.dashboard.payment$.pipe(take(1));
  }

  private withDeposit$() {
    return this.dashboard.deposit$.pipe(take(1));
  }

  private withApplicationParams$() {
    return combineLatest([
      this.userApplications.selectedApplicationGuid$,
      this.userData.registrationId$
    ]).pipe(
      map(([applicationGuid, registrationId]) => ({
        applicationGuid,
        registrationId
      })),
      take(1)
    );
  }

  private withDepositParams$() {
    return this.dashboard.depositContext$.pipe(take(1));
  }
}

function getPaymentErrorMessage({ status, error }: HttpErrorResponse) {
  const defaultError = 'Something went wrong while processing your payment.';

  const ErrorCodes = new Set([
    HttpStatusCode.BadRequest,
    HttpStatusCode.Conflict
  ]);
  if (ErrorCodes.has(status) && error?.data) {
    const err = error.data.message || error.data.msg;
    return err || defaultError;
  }
  return defaultError;
}
